<template>
  <div>
    <slot/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import getBrandInfo from './common/brand-service';
import Forbidden from './error/forbidden';
import ServerError from './error/server-error';

export default {
  name: 'ContentRoot',
  components: {
    Forbidden,
    ServerError,
  },
  data() {
    return {
      domain: getBrandInfo().domain,
    };
  },
  computed: {
    isPadded() {
      return true;
    },
    isWide() {
      return false;
    },
  },
  store: window.store,
};
</script>
