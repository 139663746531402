export default {
  props: {
    validationResultsNeeded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validationObjects: [],
    };
  },
  watch: {
    validationResultsNeeded() {
      // When mixed in this.emitMesssages() replaced
      // with exact implementation of component so the check is needed here
      if (
        this.emitMesssages === undefined ||
        typeof this.emitMesssages !== 'function' ||
        !this.validationObjects.length
      ) {
        return;
      }
      this.validationObjects.forEach(element => {
        this.emitMesssages(element);
      });
    },
  },
};
