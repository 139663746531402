var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("i", {
    staticClass: "ui icon",
    class: {
      "red close": _vm.field.$dirty && _vm.field.$invalid,
      "green check": _vm.field.$dirty && !_vm.field.$invalid
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }