<template>
    <form class="ui form">
        <h4 class="ui dividing header">{{ I18n('headerAddUpdateCustomer') }}</h4>
        <div class="field">   
            <div class="field">
                <label>{{ I18n('lblSalutation') }}</label>
            </div>   
            <div class="inline fields">
                <div class="field">
                    <div class="ui radio checkbox">
                        <input
                            v-model="contact.address.title"
                            :id="'_titleM'"
                            :name="'title'"
                            type="radio"
                            value="M"                            
                            class="hidden">
                        <label :for="'_titleM'">{{ I18n('lblGenderMale') }}</label>
                    </div>
                </div>
                <div class="field">
                    <div class="ui radio checkbox">
                        <input
                            v-model="contact.address.title"
                            :id="'_titleF'"
                            :name="'title'"
                            type="radio"
                            value="F"
                            class="hidden">
                        <label :for="'_titleF'">{{ I18n('lblGenderFemale') }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="field">             
            <div class="two fields">
                <div class="field">
                    <div
                    :class="{ 'error': $v.contact.address.firstName.$error || ($v.contact.address.firstName.$invalid && validationResultsNeeded), 'validated': $v.contact.address.firstName.$dirty && !$v.contact.address.firstName.$invalid }"
                    class="required field">
                        <label for="firstname">{{ I18n('lblFirstName') }}</label>
                        <div class="ui icon input">
                            <input
                                :id="'contact.address.firstName'"
                                :placeholder="I18n('placeholderFirstName')"     
                                v-model.trim="contact.address.firstName"
                                name="firstname"
                                data-test="firstname"
                                type="text"
                                autocomplete="given-name"
                                @focus="handleFocus();"
                                @blur="handleBlur(()=> {$v.contact.address.firstName.$touch(); emitMesssages($v.contact)});"
                                @input="$v.contact.address.firstName.$reset()"
                            >
                            <validation-icon :field="$v.contact.address.firstName"/>
                        </div>
                        <div
                            v-if="$v.contact.address.firstName.$error || ($v.contact.address.firstName.$invalid && validationResultsNeeded)"
                            class="message">{{ I18n('msgDontForgetFirstName') }}</div>
                    </div>
                </div>
                <div class="field">
                    <div
                    :class="{ 'error': $v.contact.address.lastName.$error || ($v.contact.address.lastName.$invalid && validationResultsNeeded), 'validated': $v.contact.address.lastName.$dirty && !$v.contact.address.lastName.$invalid }"
                    class="required field">
                        <label for="lastName">{{ I18n('lblLastName') }}</label>
                        <div class="ui icon input">
                            <input
                                :id="'contact.address.lastName'"
                                :placeholder="I18n('placeholderLastName')" 
                                v-model.trim="contact.address.lastName"
                                name="lastname"
                                data-test="lastname"
                                type="text"
                                autocomplete="given-name"
                                @focus="handleFocus();"
                                @blur="handleBlur(()=> {$v.contact.address.lastName.$touch(); emitMesssages($v.contact)});"
                                @input="$v.contact.address.lastName.$reset()"
                            >
                            <validation-icon :field="$v.contact.address.lastName"/>
                        </div>
                        <div
                            v-if="$v.contact.address.lastName.$error || ($v.contact.address.lastName.$invalid && validationResultsNeeded)"
                            class="message">{{ I18n('msgDontForgetLastName') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="field">
            <div class="fields">
                <div class="twelve wide field">
                    <!--input type="text" name="contact.address.street" placeholder="Street Address"-->
                    <div
                    :class="{ 'error': $v.contact.address.address1.$error || ($v.contact.address.address1.$invalid && validationResultsNeeded), 'validated': $v.contact.address.address1.$dirty && !$v.contact.address.address1.$invalid }"
                    class="required field">
                        <label for="address1">{{ I18n('lblAddress1') }}</label>
                        <div class="ui icon input">
                            <input
                                :id="'contact.address.address1'"
                                :placeholder="I18n('placeholderAddress1')" 
                                v-model.trim="contact.address.address1"
                                name="address1"
                                data-test="address1"
                                type="text"
                                autocomplete="given-name"
                                @focus="handleFocus();"
                                @blur="handleBlur(()=> {$v.contact.address.address1.$touch(); emitMesssages($v.contact)});"
                                @input="$v.contact.address.address1.$reset()"
                            >
                            <validation-icon :field="$v.contact.address.address1"/>
                        </div>
                        <div
                            v-if="$v.contact.address.address1.$error || ($v.contact.address.address1.$invalid && validationResultsNeeded)"
                            class="message">{{ I18n('msgDontForgetAddress1') }}</div>
                    </div>
                </div>
                <div class="four wide field">
                    <!--input type="text" name="contact.address.houseNumber" placeholder="Apt #"-->
                    <div
                    :class="{ 'error': $v.contact.address.address2.$error || ($v.contact.address.address2.$invalid && validationResultsNeeded), 'validated': $v.contact.address.address2.$dirty && !$v.contact.address.address2.$invalid }"
                    class="required field">
                        <label for="address2">{{ I18n('lblAddress2') }}</label>
                        <div class="ui icon input">
                            <input
                                :id="'contact.address.address2'"
                                :placeholder="I18n('placeholderAddress2')" 
                                v-model.trim="contact.address.address2"
                                name="address2"
                                data-test="address2"
                                type="text"
                                autocomplete="given-name"
                                @focus="handleFocus();"
                                @blur="handleBlur(()=> {$v.contact.address.address2.$touch(); emitMesssages($v.contact)});"
                                @input="$v.contact.address.address2.$reset()"
                            >
                            <validation-icon :field="$v.contact.address.address2"/>
                        </div>
                        <div
                            v-if="$v.contact.address.address2.$error || ($v.contact.address.address2.$invalid && validationResultsNeeded)"
                            class="message">{{ I18n('msgEnterValidAddress2') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="two fields">
            <div class="field">
                <label>State</label>
                <select v-model="contact.address.state" class="ui field fluid select"
                        :required="true"
                        :messages="validationMessages.state"
                        :validation="$v.contact.address.state">
                    <option value="">State</option>
                    <option value="KA">Karnataka</option>
                    <option value="AP">AndhraPradesh</option>
                    <option value="TN">Tamilnadu</option>
                    <option value="KL">Kerala</option>                   
                </select>
            </div>
            <div class="field">
                <label>Country</label>
                <select v-model="contact.address.country" class="ui field fluid select">
                    <option value="">Select Country</option>
                    <option value="IN">India</option>                                      
                </select>
            </div>
        </div>
        <div class="field">
             <div class="two fields">
                <div class="field">
                    <input-field
                        v-model.trim="contact.address.city"
                        :messages="validationMessages.city"
                        :required="true"
                        :validation="$v.contact.address.city"    
                        :label="I18n('lblCity')"
                        :placeholder="I18n('placeholderCustomerCity')"                              
                        autocomplete="city"
                        name="city"
                    />  
                </div>
                <div class="field">
                    <input-field
                        v-model.trim="contact.address.zipCode" type="number"
                        :messages="validationMessages.zipCode"
                        :required="false"
                        :validation="$v.contact.address.zipCode"
                        :label="I18n('lblzipCode')"
                        :placeholder="I18n('placeholderCustomerZipcode')"                              
                        autocomplete="zipCode"
                        name="zipCode"
                    />  
                </div>
             </div>
        </div>
        <div class="field">
             <div class="two fields">
                <div class="field">
                    <input-field
                        v-model.trim="contact.address.email"
                        :messages="validationMessages.email"
                        :required="true"
                        :validation="$v.contact.address.email"    
                        :label="I18n('lblEmail')"
                        :placeholder="I18n('placeholderCustomerEmail')"                              
                        autocomplete="email"
                        name="logonId"
                    />  
                </div>
                <div class="field">
                    <input-field
                        v-model.trim="contact.address.phone1" type="number"
                        :messages="validationMessages.phone1"
                        :required="false"
                        :validation="$v.contact.address.phone1"
                        :label="I18n('lblPhone1')"
                        :placeholder="I18n('placeholderCustomerPhoneNumber')"                              
                        autocomplete="phone1"
                        name="phone1"
                    />  
                </div>
             </div>
        </div>
        <button
            id="addOrUpdateCustomer"
            :disabled="!isFormValid || formState === 'pending'"
            :class="{ 'loading disabled': formState === 'pending' }"
            class="ui primary button"
            type="submit"
            @click.prevent="addOrUpdateCustomer()">{{ button.text }}</button>
    </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import salesService from './sales-service';
import validationMessages from '../common/forms/validation-messages';
import registerValidationNeededMixin from '../home/register-validation-needed-mixin';

import InputField from '../common/forms/input-field';
import ValidationIcon from '../common/forms/validation-icon';

export default {
    name : 'AddUpdateCustomer',
    components: {    
        InputField,
        ValidationIcon,
    },
    mixins: [registerValidationNeededMixin],
    data() {
        return {
            formState: undefined,
            message: undefined,   
            contact: {
                address: {
                    firstName: undefined,
                    lastName: undefined,
                    title:undefined,
                    city: undefined,
                    countryCode: undefined,
                    phone1:undefined,
                    address2: undefined,
                    address1: undefined,
                    zipCode: undefined,
                    state: undefined,
                    addressType:undefined,
                    status:undefined,
                    selfAddress:undefined,
                },
                profileType:undefined,
                registerType:undefined,
            },
            validationMessages,
             button: {
                text: 'Add'
            },
        };
    },
 computed: {
    isFormValid() {
      return (
        this.contact.address.address1 &&         
        this.contact.address.address2 && 
        !this.$v.contact.address.firstName.$invalid &&
        !this.$v.contact.address.lastName.$invalid &&
        !this.$v.contact.address.email.$invalid &&
        !this.$v.contact.address.phone1.$invalid 
        //!this.$v.contact.address.city.$invalid &&
        //!this.$v.contact.address.zipCode.$invalid 
      );
    },
  },
    methods: {
                async addOrUpdateCustomer() {
                        this.message = undefined;
                        this.formState = 'pending';
                        try {
                                //by default, registration is only for Admin User
                                this.contact.registerType='G';
                                this.contact.profileType='C';
                                this.contact.address.status=1;
                                this.contact.address.addressType='SB';
                                this.contact.address.isPrimary=true;
                                this.contact.address.selfAddress=true;
                                await salesService.saveCustomer(this.contact);
                                this.formState = 'success';
                                this.message = '';
                                window.location.assign("/sales/all-customers");
                                //console.log(JSON.stringify(this.contact));
                        } catch (error) {
                                this.formState = 'error';
                                this.message = "Failed to Save Customer Info";
                        }
                },
                getCustomerInfo(){
                const me = this;
                        if (me.$route) {
                                var selectedCustomer=me.$route.query[0];
                                if(typeof selectedCustomer == 'undefined'){
                                        this.button.text='Add';
                                        return false;
                                }
                                this.button.text='Update';
                                this.formState = "pending";
                                this.errorMessages = [];
                                try {
                                        return salesService 
                                        .getCustomerInfo(selectedCustomer)
                                        .then(response => {
                                                this.formState = "success";
                                                //console.log(JSON.stringify(response));
                                                this.contact.address.title=response.title;
                                                this.contact.address.firstName=response.firstName;
                                                this.contact.address.lastName=response.lastName;
                                                this.contact.address.address1=response.address1;
                                                this.contact.address.address2=response.address2;
                                                this.contact.address.city=response.city;
                                                this.contact.address.state=response.state;
                                                this.contact.address.country=response.country;
                                                this.contact.address.email=response.email;
                                                this.contact.address.phone1=response.phone1;
                                                this.contact.address.zipCode=response.zipcode;
                                                this.$set(this.contact,'userId',response.userId);
                                                
                                        })
                                        .catch(async response => {
                                                this.formState = "error";
                                                const error = await response.json();
                                                this.errorMessages = [error.message];
                                        });
                                } catch (error) {
                                        this.formState = "error";
                                        this.message = error.message;
                                }
                                return {}
                        }    
                },
                handleFocus() {
                const that = this;
                        setTimeout(() => {
                                that.blurAllowed = true;
                        }, 50);
                },
                handleBlur(cb) {
                if (!this.blurAllowed) return;
                        if (cb && typeof cb === 'function') {
                                cb();
                        }
                },
                getErrorMessages(validationObject) {
                const errorMessages = [];
                Object.keys(validationObject).forEach(key => {
                        if (
                                key !== 'address' &&
                                key !== '$params' &&
                                key !== '$model' &&
                                typeof validationObject[key] === 'object' &&
                                !((key === 'city' || key === 'street') && !this.hasCheckedAddress)
                        ) {
                                const messageObj = this.validationMessages[key].find(
                                        message =>
                                        !validationObject[key][message.key] && (validationObject[key].$error || validationObject[key].$invalid)
                                );
                                if (messageObj !== undefined) {
                                        errorMessages.push(messageObj);
                                }
                        }
                });
                return errorMessages;
                },
                emitMesssages(validationObject, initialCall = false) {
                let validateErrorMessages = [];
                validateErrorMessages = this.getErrorMessages(validationObject);
                validateErrorMessages = [
                        ...validateErrorMessages,
                        ...this.getErrorMessages(validationObject.address),
                ];
                if (!initialCall) {
                        this.$emit('errors', validateErrorMessages);
                }
                this.$emit('validation', {
                        isValid: !(validateErrorMessages.length > 0),
                        isInitial: initialCall,
                });
                },
        },
        beforeMount() {
                this.getCustomerInfo();
        },
        validations: {
                contact :{
                        address: {
                                firstName: {
                                        required,
                                },
                                lastName: {
                                         required,
                                },
                                title: {
                                        required,
                                },
                                city: {
                                        required,
                                },
                                countryCode: {
                                        required,
                                },
                                address2: {
                                        required,
                                },
                                address1: {
                                        required,
                                },
                                phone1: {
                                        required,
                                },
                                zipCode: {
                                        required,
                                },
                                state: {
                                        required,
                                },
                                email: {
                                        required,
                                },
                        }
                }, 
               
        },
}
</script>
