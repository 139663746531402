<template>
        <div class="ui container product" >
          <div class="alignGrid">
          <div class="ui two column grid">
            <div class="column">
              <div class="ui card">
                  <div class="image">
                    <img :src="productInfo.product.fullImage"  />
                  </div>
              </div>
            </div>
            <div class="column">
              <div class="ui card">
                <div class="content">
                  <div class="header productName">{{productInfo.product.name}}</div>
                  <div class="description">{{productInfo.product.description}}</div>
                  <div class="ui price">{{productInfo.product.listPrice}}</div>
                  <div class="description">
                    <div class="ui quantity"> Quantity
                    </div>
                    <select class="ui dropdown"  v-model="product.quantity" id="quantity">
                      <option value="1" selected>1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option> 
                    </select>
                  </div>
                  <a href="#" v-on:click="addToCart(productInfo.product.catEntryId)">
                    <div class="ui blue submit button">Add to cart</div>
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>
        </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import catalogService from "./catalog-service";
import cartService from "../cart/cart-service";
import validationMessages from "../common/forms/validation-messages";
import ErrorMessage from "../error/error-message";
export default {
       name: "ProductDetail",
        components: {
                ErrorMessage
        },
        data() {
          return {
            productInfo: undefined,
            item:{
               orderItems:[],
            },
            product:{
                productId:undefined,
                quantity:undefined,
            },
          };
        },
        methods:{
                getProductInfo(){
                  const me = this;
                  if (me.$route) {
                    var partNumber=me.$route.query[0];
                    this.formState = "pending";
                    this.errorMessages = [];
                    try {
                        return catalogService
                          .getProductByPartNumber(partNumber)
                          .then(response => {
                            this.formState = "success";
                            this.productInfo = response;
                            //console.log(JSON.stringify(response));
                          })
                      .catch(async response => {
                        this.formState = "error";
                        const error = await response.json();
                        this.errorMessages = [error.message];
                      });
                  } catch (error) {
                    this.formState = "error";
                    this.message = error.message;
                  }
                    return {}
                  }     
              },
              async addToCart(catEntryId){
                this.message = undefined;
                this.formState = 'pending';
                this.product.productId=catEntryId
                //console.log(JSON.stringify(this.item));
                
                try {
                      await cartService.addItemToCart(this.item);
                      this.formState = 'success';
                      this.message = '';
                      alert('Item added to Cart Successfully');
                      window.location.assign("/cart");
                      
                    } catch (error) {
                        this.formState = 'error';
                        this.message = "Failed Adding Item to cart";
                    }
              }
        },
        beforeMount() {
         this.getProductInfo();
      },
  };
 </script>
 
