<template>
  <div
    :class="{'very padded': padding}"
    class="ui vertical segment">
    <div
      :class="{'wide': wide}"
      class="ui container">
      <h1>Geen toegang</h1>
      <p><a href="/login">Log in</a> om deze pagina te zien.</p>
    </div>
</div></template>

<script>
export default {
  name: 'Forbidden',
  props: {
    padding: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
