/* eslint-disable class-methods-use-this */

import apiService from '../common/api-service';
//import catalogEvents from './catalog-events';
import I18n from '../internationalization/i18n';

let msg;
if (window.globalConfig !== undefined) {
	msg = new I18n(window.globalConfig.language);
} else {
	msg = new I18n('en-in');
}

class CartService {
	addItemToCart(orderItem) {
		return apiService.cart.addItemToCart(orderItem);
	}

	getCurrentCart() {
    return apiService.cart.getCurrentCart();
	}
/*
	updateItemInCart(orderItem) {
		return doRequest('put', `${langSubString()}/api/cart/item/update`, orderItem);
	}
	updateCart(order) {
		return doRequest('put', `${langSubString()}/api/cart/update`, order);
	}
*/

}

const cartService = new CartService();
export default cartService;

/* eslint-enable class-methods-use-this */
