import { render, staticRenderFns } from "./error-message.vue?vue&type=template&id=fe232a74"
import script from "./error-message.vue?vue&type=script&lang=js"
export * from "./error-message.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("F:\\SPWorkspace\\src\\github.com\\sunpro\\sites\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('fe232a74', component.options)
    } else {
      api.reload('fe232a74', component.options)
    }
    module.hot.accept("./error-message.vue?vue&type=template&id=fe232a74", function () {
      api.rerender('fe232a74', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src\\webshop\\error\\error-message.vue"
export default component.exports