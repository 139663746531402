<template>
  <div class="ui container home"><h4 class="ui dividing header">Ship Modes</h4>
    <div class="alignGrid">
      <div class="ui two column grid">
         <div class="column" v-for="p in ShipmodesList.shipmodes"
            :key="p.shipModeId"
          :id = "'catEntDiv_'+p.shipMode"
          :ref= "'catEntDiv_'+p.shipMode" >
          <div class="ui card">
            <!--
            <a href="#" v-on:click="toPDP(p.partNumber)">
              <div class="image">
                <img :src="p.thumbnail"  />
              </div>
            </a>
            -->
            <div class="content">
              <div class="header">{{p.shipMode}}</div>
              <div class="description">{{p.description}}</div>
              <div class="description">Transporter : {{p.transporter}}</div>
              <div class="description">Ship Charge : {{p.shipCharge}}</div>
              <div class="description">Ship Tax    : {{p.shipTax}}</div>
              <div>
                <a href="#" v-on:click="toEdit(p.shipMode)">
                  <div class="ui blue submit button">Update</div>
                </a>
              </div>
            </div>
          </div>
        </div>                
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import shipmodeService from "./shipmode-service";
import ErrorMessage from "../error/error-message";

export default {
  name: "Shipmodes",
  props: {
    type:String,
  },
  components: {
    ErrorMessage
  },
  data() {
    return {
      ShipmodesList: []
    };
  },

  methods: {
    getShipmodesList() {
      this.formState = "pending";
      this.errorMessages = [];
      try {
        return shipmodeService
          .getShipmodes()
          .then(response => {
            this.formState = "success";
            this.ShipmodesList = response;
            console.log(JSON.stringify(this.ShipmodesList));
          })
          .catch(async response => {
            this.formState = "error";
            const error = await response.json();
            this.errorMessages = [error.message];
          });
      } catch (error) {
        this.formState = "error";
        this.message = error.message;
      }
      return {};
    },
     toEdit(shipmodeName){
     this.$router.push({ path: '/shipping/add-update-shipmode',query: [shipmodeName]})
     this.$router.go();
    },
  },
  beforeMount() {
    this.getShipmodesList();
  }
};
</script>
