<template>
  <div class="ui container home"> <h4 class="ui dividing header">Customers</h4>
    <div class="alignGrid">
      <div class="ui two column grid">
         <div class="column" v-for="p in CustomerList.allCustomers"
            :key="p.addressId"
          :id = "'customerDiv_'+p.addressId"
          :ref= "'customerDiv_'+p.addressId" >
          <div class="ui card">
            <div class="content">
              <div class="header">{{p.title}}.{{p.firstName}}</div>
              <div class="description">Lastname : {{p.lastName}}</div>
              <div class="description">Address 1 : {{p.address1}}</div>
              <div class="description">Address 2 : {{p.address2}}</div>
              <div class="description">Email    : {{p.email}}</div>
              <div class="description">City    : {{p.city}}</div>
              <div class="description">Zip    : {{p.zipCode}}</div>
              <div>
                <a href="#" v-on:click="toEdit(p.addressId)">
                  <div class="ui blue submit button">Update</div>
                </a>
              </div>
            </div>
          </div>
        </div>                
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import salesService from "./sales-service";
import ErrorMessage from "../error/error-message";

export default {
  name: "Customers",
  props: {
    type:String,
  },
  components: {
    ErrorMessage
  },
  data() {
    return {
      CustomerList: []
    };
  },

  methods: {
    getCustomerList() {
      this.formState = "pending";
      this.errorMessages = [];
      try {
        return salesService.getCustomerList()
          .then(response => {
            this.formState = "success";
            this.CustomerList = response;
            //console.log(JSON.stringify(this.CustomerList));
          })
          .catch(async response => {
            this.formState = "error";
            const error = await response.json();
            this.errorMessages = [error.message];
          });
      } catch (error) {
        this.formState = "error";
        this.message = error.message;
      }
      return {};
    },
     toEdit(contactId){
     this.$router.push({ path: '/sales/add-update-customer',query: [contactId]})
     this.$router.go();
    },
  },
  beforeMount() {
    this.getCustomerList();
  }
};
</script>
