var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui container home" }, [
    _c("div", { staticClass: "alignGrid" }, [
      _c(
        "div",
        { staticClass: "ui two column grid" },
        _vm._l(_vm.ProductList.products, function(p) {
          return _c(
            "div",
            {
              key: p.catEntryId,
              ref: "catEntDiv_" + p.partNumber,
              refInFor: true,
              staticClass: "column",
              attrs: { id: "catEntDiv_" + p.partNumber }
            },
            [
              _c("div", { staticClass: "ui card" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        _vm.toPDP(p.partNumber)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "image" }, [
                      _c("img", { attrs: { src: p.thumbnail } })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "header" }, [
                    _vm._v(_vm._s(p.name))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description" }, [
                    _vm._v(_vm._s(p.description))
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            _vm.addToCart(p.catEntryId)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "ui blue submit button" }, [
                          _vm._v("Add to cart")
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]
          )
        })
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }