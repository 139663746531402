var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ui vertical segment",
      class: { "very padded": _vm.padding }
    },
    [
      _c("div", { staticClass: "ui container", class: { wide: _vm.wide } }, [
        _c("h1", [_vm._v("Geen toegang")]),
        _vm._v(" "),
        _vm._m(0)
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("a", { attrs: { href: "/login" } }, [_vm._v("Log in")]),
      _vm._v(" om deze pagina te zien.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }