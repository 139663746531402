const info = {
  'sunpro.in': {
    domain: 'sunpro.in',
    name: 'Sunpro',
    catalogId: '10001',
    language: 'EN',
    privacyPolicy: '/customerservice/privacy',
    country: 'in',
  },  
};

export default function getBrandInfo() {
  // blokker.nl appspot domains
 if (window.location.hostname === 'sunpro-webshop-uat.appspot.com') {
    return info['sunpro.in'];
  }

  const match = window.location.hostname.match(/(\w+\.)?(\w+\.(in))$/);
  let domain = match && match[2];
  // Fallback for Wallaby
  return info[domain] ? info[domain] : {};
}
