var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui form" }, [
      _c("div", { staticClass: "fields" }, [
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("First name")]),
          _vm._v(" "),
          _c("input", { attrs: { type: "text", placeholder: "First Name" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("Middle name")]),
          _vm._v(" "),
          _c("input", { attrs: { type: "text", placeholder: "Middle Name" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("Last name")]),
          _vm._v(" "),
          _c("input", { attrs: { type: "text", placeholder: "Last Name" } })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fields" }, [
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("Gender")]),
          _vm._v(" "),
          _c("div", { staticClass: "ui selection dropdown" }, [
            _c("input", { attrs: { type: "hidden", name: "gender" } }),
            _vm._v(" "),
            _c("i", { staticClass: "dropdown icon" }),
            _vm._v(" "),
            _c("div", { staticClass: "default text" }, [_vm._v("Gender")]),
            _vm._v(" "),
            _c("div", { staticClass: "menu" }, [
              _c("div", { staticClass: "item", attrs: { "data-value": "1" } }, [
                _vm._v("Male")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item", attrs: { "data-value": "0" } }, [
                _vm._v("Female")
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("Phone Number")]),
          _vm._v(" "),
          _c("div", { staticClass: "field" }, [
            _c("input", { attrs: { type: "text", placeholder: "xxxx" } })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fields" }, [
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("City")]),
          _vm._v(" "),
          _c("input", { attrs: { type: "text", placeholder: "City" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("State")]),
          _vm._v(" "),
          _c("input", { attrs: { type: "text", placeholder: "State" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("Country")]),
          _vm._v(" "),
          _c("input", { attrs: { type: "text", placeholder: "Country" } })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }