var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "field",
      class: { error: _vm.validation.$error, required: _vm.required }
    },
    [
      _c("label", { attrs: { for: _vm.name } }, [
        _vm._v(_vm._s(_vm.label)),
        _vm.tooltipText
          ? _c("span", { attrs: { "data-tooltip": _vm.tooltipText } }, [
              _c("i", { staticClass: "help circle outline icon link" })
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ui icon input" },
        [
          _c("input", {
            attrs: {
              autocomplete: _vm.autocomplete,
              name: _vm.name,
              id: _vm.name,
              placeholder: _vm.placeholder,
              type: _vm.type
            },
            domProps: { value: _vm.value },
            on: {
              blur: function($event) {
                _vm.validation.$touch()
                _vm.blur($event)
                _vm.updatePasswordTouched()
                _vm.emitMesssages(_vm.validation, $event.target.name)
              },
              input: function($event) {
                _vm.updateValue($event.target.value)
                _vm.validation.$reset()
              }
            }
          }),
          _vm._v(" "),
          _c("validation-icon", { attrs: { field: _vm.validation } })
        ],
        1
      ),
      _vm._v(" "),
      _vm.validation.$error && _vm.visibleMessage
        ? _c("div", { staticClass: "message" }, [
            _vm._v(_vm._s(_vm.visibleMessage))
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }