<template>
  <div class="ui very padded vertical segment">
    <div class="ui wide container">
      <div class="ui grid">
        <div class="one wide tablet two wide computer column"/>
        <div class="twelve wide tablet eight wide computer center aligned column">
          <h1 class="ui large header">Oeps, er is iets misgegaan</h1>
          <p>
            Het lijkt erop dat het ophalen van de order is mislukt.
            <br>
            Klik op het logo of op de button om naar de hoofdpagina te gaan.
            <br>
            Success!
          </p>
          <a
            href="/"
            class="ui primary button">naar de homepage</a>
        </div>
        <ul class="ui flat list">
          <li
            v-for="message in errorMessages"
            :key="message">{{ message }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ServerError',
  props: {
    padding: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },
  computed: mapState('cart', ['errorMessages']),
};
</script>
