export default {
  /* Labels */
  lblLoginId: 'LoginId',  
  lblEmail: 'Email',  
  lblPwd: 'Password',
  lblFirstName: 'First Name',
  lblLastName: 'Last Name',
  lblAddress1: 'Address',
  lblAddress2: 'House#',
  lblPhone1: 'Phone',
  lblCity:'City',
  lblzipCode:'Zipcode',
  lblSalutation: 'Salutation',
  lblGenderFemale: 'Female',
  lblGenderMale: 'Male',
  lblName: 'Name',
  lblSubject: 'Subject',
  lblComments: 'Comments',
  lblSearch: 'Search',
  //Product related
  lblProductDescription: 'Description',
  lblMfPartNumber:'Manufacturer Partnumber',
  lblPartNumber: 'Product Number',
  lblMfName:'Manufacturer Name',
  lblKeyword:'Keyword',
  lblListPrice:'Price',
  lblQuantity:'Quantity',
  lblThumbnail:'Thumbnail',
  lblFullImage:'Full Image',
  // Shipmode related
  lblShipMode:'Mode',
  lblShipModeDescription:'Description',
  lblShipCharge:'Shipping Charge',
  lblShipTax:'Shipping Tax',
  lblTransporter:'Transporter',
  lblTrackingCode:'Tracking Code',
  
  /* Messages */
  msgDontForgetEmail: 'Dont forget to enter your e-mail address',
  msgDontForgetEmailPlease: 'Please enter your e-mail',
  msgPwdPleaseEnter: 'Please enter your password',
  msgEnterValidEmail: 'Please enter a valid e-mail address, for example: name@domain.com',
  msgDontForgetFirstName: 'Dont forget to enter your first name',
  msgDontForgetName:'Please enter your first name',
  msgDontForgetLastName: 'Dont forget to enter your last name',
  msgDontForgetZipCode: 'Dont forget to enter your zip code',
  msgDontForgetPhoneNumber: 'Dont forget to enter your Phone Number',
  msgDontForgetAddress2: 'Dont forget to enter your house number',    
  msgDontForgetAddress1: 'Dont forget to enter your Address',
  msgDontForgetResidence: 'Dont forget to enter your City',
  msgDontForgetPhoneNr: 'Dont forget to enter your phone number',
  msgDontForgetCountryCode:'Dont forget to enter your country code',
  msgEnterValidHouseNr:'Dont forget to enter your valid house number',
  msgDontForgetState:'Dont forget to enter your state code',
  msgDontForgetGender:'Dont forget to enter your gender',
  msgDontForgetSubject:'Dont forget to enter Subject',
  msgDontForgetComments:'Dont forget to enter Comments',
  msgEnterValidAddress2:'Enter Valid Address line 2',


  //Product related
  msgDontForgetPartNumber:'Dont forget to enter Product number',
  msgDontForgetMfPartNumber:'Dont forget to enter Manufacturer Product number',
  msgDontForgetMfName:'Dont forget to enter Manufacturer name',
  msgDontForgetMfName:'Dont forget to enter Manufacturer name',
  msgDontForgetCatEntType:'Dont forget to enter CatEntType',
  msgDontForgetListPrice:'Dont forget to enter Price',
  msgDontForgetQuantity:'Dont forget to enter Quantity',
  msgDontForgetMarkForDelete:'Dont forget to enter Delete flag',
  msgDontForgetMfName:'Dont forget to enter Manufacturer name',
  msgDontForgetMfName:'Dont forget to enter Manufacturer name',
  msgDontForgetProductDescription:'Please enter the product description',
  msgDontForgetLangId:'Please enter the product language Id',
  msgDontForgetProductName:'Please enter the product name',
  msgDontForgetProductKeyword:'Please enter the product keyword',
  msgDontForgetProductAvailable:'Please enter the product availablity',
  msgDontForgetProductPublished:'Please enter the product publish status',
  msgDontForgetProductThumbnail:'Please enter the Product Thumbnail path',
  msgDontForgetProductFullImage:'Please enter the Product Full Image path',
 
  /* Placeholders */
  placeholderEmail: 'Your e-mail address (This is your LogonId)',
  placeholderEmail1: 'Your e-mail address',
  placeholderCustomerEmail: 'E-mail address',
  placeholderPhone1: 'Your Primary Phone Number',
  placeholderCustomerPhoneNumber: 'Primary Phone Number',
  placeholderFirstName: 'First Name..',
  placeholderLastName: 'Last Name..',
  placeholderAddress1:'Street Address..',
  placeholderAddress2: '#apt',  
  placeholderSubject: 'Subject',  
  placeholderComments: 'Your Message here..',  
  placeholderCustomerCity:'City',
  placeholderCustomerZipcode:'Zipcode',
  
  //Product related
  placeholderPartNumber:'Partnumber',
  placeholderMfPartNumber:'MFPartnumber',
  placeholderMfName:'Manufacturer Name',
  placeholderProductName:'Product Name',
  placeholderProductKeyword: 'Product Keyword',
  placeholderProductDescription: 'Enter the product description',
  placeholderProductListPrice: 'Product  price',
  placeholderProductQuantity: 'Product quantity',
  placeholderProductThumbnail:'Proudct Thumbnail Path',
  placeholderProductFullImage:'Product Full Image Path',

  // Shipmode related
  placeholderShipMode:'Shipping method here',
  placeholderShipModeDescription:'Description here',
  placeholderShipCharge:'Enter Shipping charge ',
  placeholderShipTax:'Enter Shipping Tax',
  placeholderTransporter:'Transporter name ',
  placeholderTrackingCode:'Tracking code ',

  /* Headers */
  headerRegistration: 'Register Information',
  headerAddUpdateCustomer:'Customer Information',

  /*Error*/
  msgErrorLogin: 'Failed to Login.',
  msgErrorSendEmail: 'Failed to send email. Please try later..',

  /*Cart*/
  placeholderSearchCustomer:'By First name / Last name',

};
