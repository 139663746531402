/* eslint-disable class-methods-use-this */

import apiService from '../common/api-service';
import accountEvents from './account-events';
import I18n from '../internationalization/i18n';

let msg;
if (window.globalConfig !== undefined) {
  msg = new I18n(window.globalConfig.language);
} else {
  msg = new I18n('en-in');
}

class AccountService {
  currentLoginId() {
    return localStorage.getItem('login-id') || undefined;
  }

  cacheLoginId(loginId) {
    localStorage.setItem('login-id', loginId);
    accountEvents.$emit('logged-in', loginId);
  }

  deleteLoginId() {
    localStorage.removeItem('login-id');
    accountEvents.$emit('logged-out');
  }

  async login(logonId, password) {
    try {
      await apiService.account.login(logonId, password);
      this.cacheLoginId(logonId);
    } catch (error) {
        const errorBody = await error.json();
        if (errorBody.error === undefined) {
          throw new Error('Login Failed');
        }
        if (errorBody.error === 'the current password has expired') {
          // @FIXME: This does not work when the user opens the "forgot password mail" in a different web client
          sessionStorage.setItem('password-reset-loginId', loginId);
          throw new Error(msg.localize('msgPwdChangedRequired'));
        }
        throw new Error(errorBody.message);
    }    
    return true;
  }

  async logout() {
    try {
      await apiService.account.logout();
      sessionStorage.removeItem('password-reset-loginId');
      this.deleteLoginId();
    } catch (error) {
      throw new Error(msg.localize('msgErrorLogin'));
    }

    return true;
  }
  /*
  registerWithAddressAndLogin(loginId, password) {
    return apiService.account.registerWithAddressAndLogin(loginId, password);
  }*/

  registerUser(contact) {
    return apiService.account.registerUser(contact);
  }

  async sendEmail(firstName, email, subject, comments) {
    try {
      await apiService.account.sendEmail(firstName, email, subject, comments);
    } catch (error) {
      throw new Error(msg.localize('msgErrorSendEmail'));
    }
    return true;
  }

  /*getCurrentUser() {
    return apiService.account.getCurrentUser();
  }

  saveCurrentUser(contact) {
    return apiService.account.saveCurrentUser(contact);
  }

  async resetPassword(loginId) {
    try {
      await apiService.account.resetPassword(loginId);
    } catch (error) {
      const errorBody = await error.json();
      throw new Error(errorBody.message);
    }
  }

  async updatePassword(loginId, oldPassword, newPassword) {
    try {
      await apiService.account.updatePassword(
        loginId,
        oldPassword,
        newPassword
      );
      sessionStorage.removeItem('password-reset-loginId');
    } catch (error) {
      let errorMessage;

      try {
        const errorBody = await error.json();
        if (errorBody.error !== undefined) {
          errorMessage = errorBody.message;
        }
      } catch (parseError) {
        errorMessage = 'Wachtwoord update mislukt';
      }

      throw new Error(errorMessage);
    }
  }

  changePassword(password) {
    return apiService.account.changePassword(password);
  }

  getOrders(omsHistory) {
    if (omsHistory) {
      return apiService.orders.getAllOrders();
    }
    return apiService.orders.getAll();
  }*/
}

const accountService = new AccountService();
export default accountService;

/* eslint-enable class-methods-use-this */
