<template>
    <form class="ui form">
        <h4 class="ui dividing header">{{ I18n('headerRegistration') }}</h4>
        <div class="field">   
            <div class="field">
                <label>{{ I18n('lblSalutation') }}</label>
            </div>   
            <div class="inline fields">
                <div class="field">
                    <div class="ui radio checkbox">
                        <input
                            v-model="contact.title"
                            :id="'_titleM'"
                            :name="'title'"
                            type="radio"
                            value="M"                            
                            class="hidden">
                        <label :for="'_titleM'">{{ I18n('lblGenderMale') }}</label>
                    </div>
                </div>
                <div class="field">
                    <div class="ui radio checkbox">
                        <input
                            v-model="contact.title"
                            :id="'_titleF'"
                            :name="'title'"
                            type="radio"
                            value="F"
                            class="hidden">
                        <label :for="'_titleF'">{{ I18n('lblGenderFemale') }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="field">             
            <div class="two fields">
                <div class="field">
                    <div
                    :class="{ 'error': $v.contact.address.firstName.$error || ($v.contact.address.firstName.$invalid && validationResultsNeeded), 'validated': $v.contact.address.firstName.$dirty && !$v.contact.address.firstName.$invalid }"
                    class="required field">
                        <label for="firstname">{{ I18n('lblFirstName') }}</label>
                        <div class="ui icon input">
                            <input
                                :id="'contact.address.firstName'"
                                :placeholder="I18n('placeholderFirstName')"     
                                v-model.trim="contact.address.firstName"
                                name="firstname"
                                data-test="firstname"
                                type="text"
                                autocomplete="given-name"
                                @focus="handleFocus();"
                                @blur="handleBlur(()=> {$v.contact.address.firstName.$touch(); emitMesssages($v.contact)});"
                                @input="$v.contact.address.firstName.$reset()"
                            >
                            <validation-icon :field="$v.contact.address.firstName"/>
                        </div>
                        <div
                            v-if="$v.contact.address.firstName.$error || ($v.contact.address.firstName.$invalid && validationResultsNeeded)"
                            class="message">{{ I18n('msgDontForgetFirstName') }}</div>
                    </div>
                </div>
                <div class="field">
                    <div
                    :class="{ 'error': $v.contact.address.lastName.$error || ($v.contact.address.lastName.$invalid && validationResultsNeeded), 'validated': $v.contact.address.lastName.$dirty && !$v.contact.address.lastName.$invalid }"
                    class="required field">
                        <label for="lastName">{{ I18n('lblLastName') }}</label>
                        <div class="ui icon input">
                            <input
                                :id="'contact.address.lastName'"
                                :placeholder="I18n('placeholderLastName')" 
                                v-model.trim="contact.address.lastName"
                                name="lastname"
                                data-test="lastname"
                                type="text"
                                autocomplete="given-name"
                                @focus="handleFocus();"
                                @blur="handleBlur(()=> {$v.contact.address.lastName.$touch(); emitMesssages($v.contact)});"
                                @input="$v.contact.address.lastName.$reset()"
                            >
                            <validation-icon :field="$v.contact.address.lastName"/>
                        </div>
                        <div
                            v-if="$v.contact.address.lastName.$error || ($v.contact.address.lastName.$invalid && validationResultsNeeded)"
                            class="message">{{ I18n('msgDontForgetLastName') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="field">
            <div class="fields">
                <div class="twelve wide field">
                    <!--input type="text" name="contact.address.street" placeholder="Street Address"-->
                    <div
                    :class="{ 'error': $v.contact.address.address1.$error || ($v.contact.address.address1.$invalid && validationResultsNeeded), 'validated': $v.contact.address.address1.$dirty && !$v.contact.address.address1.$invalid }"
                    class="required field">
                        <label for="address1">{{ I18n('lblAddress1') }}</label>
                        <div class="ui icon input">
                            <input
                                :id="'contact.address.address1'"
                                :placeholder="I18n('placeholderAddress1')" 
                                v-model.trim="contact.address.address1"
                                name="address1"
                                data-test="address1"
                                type="text"
                                autocomplete="given-name"
                                @focus="handleFocus();"
                                @blur="handleBlur(()=> {$v.contact.address.address1.$touch(); emitMesssages($v.contact)});"
                                @input="$v.contact.address.address1.$reset()"
                            >
                            <validation-icon :field="$v.contact.address.address1"/>
                        </div>
                        <div
                            v-if="$v.contact.address.address1.$error || ($v.contact.address.address1.$invalid && validationResultsNeeded)"
                            class="message">{{ I18n('msgDontForgetAddress1') }}</div>
                    </div>
                </div>
                <div class="four wide field">
                    <!--input type="text" name="contact.address.houseNumber" placeholder="Apt #"-->
                    <div
                    :class="{ 'error': $v.contact.address.address2.$error || ($v.contact.address.address2.$invalid && validationResultsNeeded), 'validated': $v.contact.address.address2.$dirty && !$v.contact.address.address2.$invalid }"
                    class="required field">
                        <label for="address2">{{ I18n('lblAddress2') }}</label>
                        <div class="ui icon input">
                            <input
                                :id="'contact.address.address2'"
                                :placeholder="I18n('placeholderAddress2')" 
                                v-model.trim="contact.address.address2"
                                name="address2"
                                data-test="address2"
                                type="text"
                                autocomplete="given-name"
                                @focus="handleFocus();"
                                @blur="handleBlur(()=> {$v.contact.address.address2.$touch(); emitMesssages($v.contact)});"
                                @input="$v.contact.address.address2.$reset()"
                            >
                            <validation-icon :field="$v.contact.address.address2"/>
                        </div>
                        <div
                            v-if="$v.contact.address.address2.$error || ($v.contact.address.address2.$invalid && validationResultsNeeded)"
                            class="message">{{ I18n('msgEnterValidAddress2') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="two fields">
            <div class="field">
                <label>State</label>
                <select class="ui field fluid select">
                    <option value="">State</option>
                    <option value="KA">Karnataka</option>
                    <option value="AP">AndhraPradesh</option>
                    <option value="TN">Tamilnadu</option>
                    <option value="KL">Kerala</option>                   
                </select>
            </div>
            <div class="field">
                <label>Country</label>
                <select class="ui field fluid select">
                    <option value="">Select Country</option>
                    <option value="IN">India</option>                                      
                </select>
            </div>
        </div>
        <div class="field">
             <div class="two fields">
                <div class="field">
                    <input-field
                        v-model.trim="contact.logonId"
                        :messages="validationMessages.username"
                        :required="true"
                        :validation="$v.contact.logonId"    
                        :label="I18n('lblEmail')"
                        :placeholder="I18n('placeholderEmail')"                              
                        autocomplete="email"
                        name="logonId"
                    />  
                </div>
                <div class="field">
                    <input-field
                        v-model.trim="contact.address.phone1" type="number"
                        :messages="validationMessages.phone1"
                        :required="false"
                        :validation="$v.contact.address.phone1"
                        :label="I18n('lblPhone1')"
                        :placeholder="I18n('placeholderPhone1')"                              
                        autocomplete="phone1"
                        name="phone1"
                    />  
                </div>
             </div>
             <div class="two fields">                
                <div class="field">
                    <input-field
                        v-model="contact.password"     
                        :messages="validationMessages.password"                   
                        :required="true"
                        :validation="$v.contact.password"  
                        :label="I18n('lblPwd')"
                        :placeholder="I18n('lblPwd')"                    
                        autocomplete="off"
                        name="password"
                        type="password"
                    />
                </div>
             </div>            
        </div>
        <div class="field">
            <div class="ui checkbox">
            <!--input type="checkbox" tabindex="0" class="hidden"-->
            <input
                id="agree"
                v-model="agree"
                :required="true"
                name="agree"
                type="checkbox" >            
                <label>I agree to the Terms and Conditions</label>
            </div>
        </div>
        <button
            id="register"
            :disabled="!isFormValid || formState === 'pending'"
            :class="{ 'loading disabled': formState === 'pending' }"
            class="ui primary button"
            type="submit"
            @click.prevent="registerUser()">Submit</button>
    </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import accountService from './account-service';
import validationMessages from '../common/forms/validation-messages';
import registerValidationNeededMixin from './register-validation-needed-mixin';

import InputField from '../common/forms/input-field';
import ValidationIcon from '../common/forms/validation-icon';

export default {
    name : 'RegisterForm',
    components: {    
        InputField,
        ValidationIcon,
    },
    mixins: [registerValidationNeededMixin],
    data() {
        return {
            formState: undefined,
            message: undefined,   
            contact: {
                address: {
                    firstName: undefined,
                    lastName: undefined,
                    title:undefined,
                    city: undefined,
                    countryCode: undefined,
                    phone1:undefined,
                    address2: undefined,
                    address1: undefined,
                    zipCode: undefined,
                    state: undefined,
                    addressType:undefined,
                },
                firstName: undefined,
                lastName: undefined,
                logonId: undefined,
                password: undefined,
                profileType:undefined,
                registerType:undefined,
            },
            validationMessages,
            agree:false,
        };
    },
 computed: {
    isFormValid() {
      return (
        this.contact.address.address1 &&         
        this.contact.address.address2 && 
        this.agree &&        
        !this.$v.contact.address.firstName.$invalid &&
        !this.$v.contact.address.lastName.$invalid &&
        !this.$v.contact.logonId.$invalid &&
        !this.$v.contact.password.$invalid 
      );
    },
  },
    validations: {
        contact :{
            logonId: {
                required,
            },
            password: {
                required,
            },
            address: {
            firstName: {
                required,
            },
            lastName: {
                required,
            },
                title: {
                required,
            },
                city: {
                    required,
                },
                countryCode: {
                    required,
                },
                address2: {
                    required,
                },
                address1: {
                    required,
                },
                phone1: {
                    required:false,
                },
                zipCode: {
                    required,
                },
                state: {
                    required,
                },
            }
        }, 
        agree : {
            required,
        },       
    },
    methods: {
        async registerUser() {
            this.message = undefined;
            this.formState = 'pending';
            try {
                //by default, registration is only for Admin User
                this.contact.registerType='R';
                this.contact.profileType='A';
                this.contact.address.addressType='SB';
                await accountService.registerUser(this.contact);
                this.formState = 'success';
                this.message = '';
                window.location.assign("/");
                //console.log(JSON.stringify(this.contact));
            } catch (error) {
                this.formState = 'error';
                this.message = "Invalid Credentials";
            }
        },
        handleFocus() {
            const that = this;
            setTimeout(() => {
                that.blurAllowed = true;
            }, 50);
        },
        handleBlur(cb) {
            if (!this.blurAllowed) return;
            if (cb && typeof cb === 'function') {
                cb();
            }
        },
        getErrorMessages(validationObject) {
            const errorMessages = [];
            Object.keys(validationObject).forEach(key => {
                if (
                key !== 'address' &&
                key !== '$params' &&
                key !== '$model' &&
                typeof validationObject[key] === 'object' &&
                !((key === 'city' || key === 'street') && !this.hasCheckedAddress)
                ) {
                const messageObj = this.validationMessages[key].find(
                    message =>
                    !validationObject[key][message.key] &&
                    (validationObject[key].$error || validationObject[key].$invalid)
                );
                if (messageObj !== undefined) {
                    errorMessages.push(messageObj);
                }
                }
            });
            return errorMessages;
        },
        emitMesssages(validationObject, initialCall = false) {
            let validateErrorMessages = [];
            validateErrorMessages = this.getErrorMessages(validationObject);
            validateErrorMessages = [
                ...validateErrorMessages,
                ...this.getErrorMessages(validationObject.address),
            ];
            if (!initialCall) {
                this.$emit('errors', validateErrorMessages);
            }
            this.$emit('validation', {
                isValid: !(validateErrorMessages.length > 0),
                isInitial: initialCall,
            });
        },
  },
}
</script>
