<template>
  <div class="ui container home">
    <div class="alignGrid">
      <div class="ui two column grid">

        <div class="column" v-for="p in AllProductsList.products"
            :key="p.catEntryId"
          :id = "'catEntDiv_'+p.partNumber"
          :ref= "'catEntDiv_'+p.partNumber" >
          <div class="ui card">
            
            <a href="#" v-on:click="toEdit(p.partNumber)">
              <div class="image">
                <img :src="p.thumbnail"  />
              </div>
            </a>
            
            <div class="content">
              <div class="header">{{p.name}}</div>
              <div
                class="description">{{p.description}}</div>
              <div>
                <a href="#" v-on:click="toEdit(p.partNumber)">
                  <div class="ui blue submit button">Update</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import catalogService from "./catalog-service";
import validationMessages from "../common/forms/validation-messages";
import ErrorMessage from "../error/error-message";

export default {
  name: "AllProducts",
  props: {
    type:String,
  },
  components: {
    ErrorMessage
  },
  data() {
    return {
      AllProductsList: []
    };
  },

  methods: {
    getAllProducts() {
      this.formState = "pending";
      this.errorMessages = [];
      try {
        return catalogService
          .getProductList()
          .then(response => {
            this.formState = "success";
            this.AllProductsList = response;
            //console.log(JSON.stringify(this.ProductList));
          })
          .catch(async response => {
            this.formState = "error";
            const error = await response.json();
            this.errorMessages = [error.message];
          });
      } catch (error) {
        this.formState = "error";
        this.message = error.message;
      }
      return {};
    },
    toEdit(partNumber){
     this.$router.push({ path: '/catalog/add-update-product',query: [partNumber]})
     this.$router.go();
    },
  },
  beforeMount() {
    this.getAllProducts();
  }
};
</script>
