<template>
  <div>   
    <br> 
    <div class="ui centered grid">
      <div  v-if="formState === 'error'" 
            class="ui error message">{{message}}</div>
    </div>
    <div class="ui centered grid">
      <div class="ui placeholder segment ">        
          <div class="ui two column centered grid">
            <div class="column">
              <div class="ui form">                              
                <input-field
                  v-model.trim="logonId"
                  :messages="validationMessages.logonId"
                  :required="true"
                  :validation="$v.logonId"    
                  :label="I18n('lblLoginId')"
                  :placeholder="I18n('placeholderEmail')"                              
                  autocomplete="email"
                  name="logonId"
                />                 
                <input-field
                  v-model="password"
                  :messages="validationMessages.password"
                  :required="true"
                  :validation="$v.password"  
                  :label="I18n('lblPwd')"
                  :placeholder="I18n('lblPwd')"                    
                  autocomplete="off"
                  name="password"
                  type="password"
                />
                <button
                  id="login"
                  :class="{ 'loading disabled': formState === 'pending' }"
                  class="ui primary button"
                  type="submit"
                  @click.prevent="login()">Login</button>
              </div>              
            </div>
            <div class="middle aligned column">
              <div class="ui big button" @click.prevent="register()">
                <i class="signup icon"></i>
                Sign Up
              </div>
            </div>
          </div>
          <div class="ui vertical divider">
            Or
          </div>        
      </div> 
    </div>
    <br>
  </div>
  
</template>
<script>

import { required } from 'vuelidate/lib/validators';
import accountService from './account-service';
import validationMessages from '../common/forms/validation-messages';

import InputField from '../common/forms/input-field';



export default {
 name: 'LoginForm',
 props: {
    redirectTo: {
      type: String,
      required: true,
    },
  },
  components: {    
    InputField,
  },
  data() {
    return {
      formState: undefined,
      logonId: undefined,
      password: undefined,   
      message: undefined,   
      validationMessages,
    };
  },
  mounted() {
    this.logonId = accountService.currentLoginId();
    if (localStorage.getItem('stored-username') !== null) {
      this.logonId = localStorage.getItem('stored-username');
    }
  },
  methods: {
    async login() {
      this.message = undefined;
      this.formState = 'pending';
      try {
        await accountService.login(this.logonId, this.password);
        this.formState = 'success';
        this.message = '';
        window.location.assign(this.redirectTo);
      } catch (error) {        
        this.formState = 'error';
        this.message = "Invalid Credentials";
      }
    },    
    saveEmail() {
      if (localStorage.getItem('stored-username') === null && this.logonId) {
        localStorage.setItem('stored-username', this.logonId);
      }
    },
    register() {
      window.location.assign("/register");
    }
  },
  validations: {
    logonId: {
      required,
    },
    password: {
      required,
    },
  },
};
</script>