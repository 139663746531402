var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("br"),
    _vm._v(" "),
    _c("div", { staticClass: "ui centered grid" }, [
      _vm.formState === "error"
        ? _c("div", { staticClass: "ui error message" }, [
            _vm._v(_vm._s(_vm.message))
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ui centered grid" }, [
      _c("div", { staticClass: "ui placeholder segment " }, [
        _c("div", { staticClass: "ui two column centered grid" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              { staticClass: "ui form" },
              [
                _c("input-field", {
                  attrs: {
                    messages: _vm.validationMessages.logonId,
                    required: true,
                    validation: _vm.$v.logonId,
                    label: _vm.I18n("lblLoginId"),
                    placeholder: _vm.I18n("placeholderEmail"),
                    autocomplete: "email",
                    name: "logonId"
                  },
                  model: {
                    value: _vm.logonId,
                    callback: function($$v) {
                      _vm.logonId = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "logonId"
                  }
                }),
                _vm._v(" "),
                _c("input-field", {
                  attrs: {
                    messages: _vm.validationMessages.password,
                    required: true,
                    validation: _vm.$v.password,
                    label: _vm.I18n("lblPwd"),
                    placeholder: _vm.I18n("lblPwd"),
                    autocomplete: "off",
                    name: "password",
                    type: "password"
                  },
                  model: {
                    value: _vm.password,
                    callback: function($$v) {
                      _vm.password = $$v
                    },
                    expression: "password"
                  }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "ui primary button",
                    class: { "loading disabled": _vm.formState === "pending" },
                    attrs: { id: "login", type: "submit" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.login()
                      }
                    }
                  },
                  [_vm._v("Login")]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "middle aligned column" }, [
            _c(
              "div",
              {
                staticClass: "ui big button",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.register()
                  }
                }
              },
              [
                _c("i", { staticClass: "signup icon" }),
                _vm._v("\n              Sign Up\n            ")
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ui vertical divider" }, [
          _vm._v("\n          Or\n        ")
        ])
      ])
    ]),
    _vm._v(" "),
    _c("br")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }