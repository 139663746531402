/* eslint-disable class-methods-use-this */

import apiService from '../common/api-service';
//import catalogEvents from './catalog-events';
import I18n from '../internationalization/i18n';

let msg;
if (window.globalConfig !== undefined) {
  msg = new I18n(window.globalConfig.language);
} else {
  msg = new I18n('en-in');
}

class CatalogService {
  addProduct(product) {
    return apiService.catalog.addProduct(product);
  }
  updateProduct(product){
    return apiService.catalog.updateProduct(product);
  }
  getProductList(){
    return apiService.catalog.getProductList();
  }
  getProductByPartNumber(partNumber){
    return apiService.catalog.getProductByPartNumber(partNumber);
  } 
}

const catalogService = new CatalogService();
export default catalogService;

/* eslint-enable class-methods-use-this */
