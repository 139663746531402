<template>
    <form>
        <div class="ui form">
            <div class="fields">
                <div class="field">                
                    <input-field
                        v-model.trim="shipmodeobj.shipMode"
                        :messages="validationMessages.shipMode"
                        :required="true"
                        :validation="$v.shipmodeobj.shipMode"    
                        :label="I18n('lblShipMode')"
                        :placeholder="I18n('placeholderShipMode')"                              
                        autocomplete="given-name"
                        name="shipMode"
                    /> 
                </div>
                <div class="field">
                    <input-field
                        v-model.trim="shipmodeobj.description"
                        :messages="validationMessages.shipModedescription"
                        :required="false"
                        :validation="$v.shipmodeobj.description"    
                        :label="I18n('lblShipModeDescription')"
                        :placeholder="I18n('placeholderShipModeDescription')"                              
                        autocomplete="given-name"
                        name="description"
                    /> 
                </div>
                <div class="field">
                    <input-field
                        v-model.trim="shipmodeobj.shipCharge" type="number" min="0" max="99999"
                        :messages="validationMessages.shipCharge"
                        :required="true"
                        :validation="$v.shipmodeobj.shipCharge"    
                        :label="I18n('lblShipCharge')"
                        :placeholder="I18n('placeholderShipCharge')"                              
                        autocomplete="given-name"
                        name="shipCharge"
                    />  
                </div> 
                <div class="field">
                    <input-field
                        v-model.trim="shipmodeobj.shipTax" type="number" min="0" max="99999"
                        :messages="validationMessages.shipTax"
                        :required="false"
                        :validation="$v.shipmodeobj.shipTax"    
                        :label="I18n('lblShipTax')"
                        :placeholder="I18n('placeholderShipTax')"                              
                        autocomplete="given-name"
                        name="shipTax"
                    />  
                </div>           
            </div>
            <div class="fields">
                <div class="field">
                    <div class="ui checkbox">
                        <input
                            id="default"
                            v-model="shipmodeobj.default"
                            :required="false"
                            name="default"
                            type="checkbox" >            
                        <label>Is Default</label>
                    </div>
                </div>
                <div class="field">
                    <div class="ui checkbox">
                        <input
                            id="markForDelete"
                            v-model="shipmodeobj.markForDelete"
                            :required="false"
                            name="markForDelete"
                            type="checkbox" >            
                        <label>For Delete</label>
                    </div>
                </div>             
            </div>
            <div class="fields">
                <div class="field">                
                    <input-field
                        v-model.trim="shipmodeobj.transporter"
                        :messages="validationMessages.transporter"
                        :required="false"
                        :validation="$v.shipmodeobj.transporter"    
                        :label="I18n('lblTransporter')"
                        :placeholder="I18n('placeholderTransporter')"                              
                        autocomplete="given-name"
                        name="transporter"
                    /> 
                </div>
                 <div class="field">                
                    <input-field
                        v-model.trim="shipmodeobj.trackingCode"
                        :messages="validationMessages.trackingCode"
                        :required="false"
                        :validation="$v.shipmodeobj.trackingCode"    
                        :label="I18n('lblTrackingCode')"
                        :placeholder="I18n('placeholderTrackingCode')"                              
                        autocomplete="given-name"
                        name="trackingCode"
                    /> 
                </div>
            </div>
            <button
                id="addOrUpdateShipmode"
                :disabled="!isFormValid || formState === 'pending'"
                :class="{ 'loading disabled': formState === 'pending' }"
                class="ui primary button"
                type="submit"
                @click.prevent="addOrUpdateShipMode()">{{ button.text }}</button>    
        </div>
    </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import shipmodeService from "./shipmode-service";
import validationMessages from '../common/forms/validation-messages';

import InputField from '../common/forms/input-field';
import ValidationIcon from '../common/forms/validation-icon';
import ValidationMessage from '../common/forms/validation-message';

export default {
    name : 'AddUpdateShipmode',
    components: {    
        InputField,
        ValidationIcon,
        ValidationMessage,
    },
    data() {
        return {
            formState: undefined,
            message: undefined,
            shipmodeobj: {
                shipMode: undefined,
                description: undefined,
                shipCharge: undefined,
                shipTax:undefined,
                transporter:undefined,
                trackingCode:undefined,
                default:undefined,
                markForDelete:undefined
            }, 
            validationMessages,
             button: {
                text: 'Add'
            },
        };
    },
    computed: {
    isFormValid() {
      return (
        this.shipmodeobj.shipMode &&
        this.shipmodeobj.shipCharge           
        /*this.shipmodeobj.description && 
        this.shipmodeobj.shipCharge && 
        this.shipmodeobj.shipTax && 
        this.shipmodeobj.transporter && 
        this.shipmodeobj.trackingCode 
        */
               
      );
    },
  },
    methods: {
        async addOrUpdateShipMode() {
            this.message = undefined;
            this.formState = 'pending';
            var shipcharge =parseFloat(this.shipmodeobj.shipCharge)
            this.shipmodeobj.shipCharge = shipcharge
             var shiptax =parseFloat(this.shipmodeobj.shipTax)
            this.shipmodeobj.shipTax = shiptax
            if(this.shipmodeobj.default ==true){
                this.shipmodeobj.default=1
            }
            if(this.shipmodeobj.markForDelete ==true){
                this.shipmodeobj.markForDelete=1
            }
            console.log(JSON.stringify(this.shipmodeobj));
            //console.log(this.button.text);
            
            try {
                if(this.button.text =='Add'){
                     await shipmodeService.addShipmode(this.shipmodeobj);
                }else{
                     await shipmodeService.updateShipmode(this.shipmodeobj);
                }
                 this.formState = 'success';
                 this.message = '';
                 window.location.assign("/shipping/shipmodes");
            } catch (error) {
                this.formState = 'error';
                this.message = "Failed to Save Shipmode Info";
            }
        },
         getShipmodeInfo(){
            const me = this;
            if (me.$route) {
                var selectedShipmode=me.$route.query[0];
                if(typeof selectedShipmode == 'undefined'){
                    this.button.text='Add';
                    return false;
                }
                this.button.text='Update';
                this.formState = "pending";
                this.errorMessages = [];
                try {
                    return shipmodeService 
                        .getShipmodeByName(selectedShipmode)
                        .then(response => {
                            this.formState = "success";
                            //console.log(JSON.stringify(response));
                            
                            this.shipmodeobj.shipMode=response.shipmode.shipMode;
                            this.shipmodeobj.description=response.shipmode.description;
                            this.shipmodeobj.shipCharge=response.shipmode.shipCharge.toString();
                            this.shipmodeobj.shipTax=response.shipmode.shipTax.toString();
                            this.shipmodeobj.transporter=response.shipmode.transporter;
                            this.shipmodeobj.trackingCode=response.shipmode.trackingCode;
                            if(response.shipmode.default == 1){
                                this.shipmodeobj.default=true;
                            }
                            if(response.shipmode.markForDelete == 1){
                                this.shipmodeobj.markForDelete=true;
                            }
                            this.$set(this.shipmodeobj,'shipModeId',response.shipmode.shipModeId);
                            
                        })
                    .catch(async response => {
                    this.formState = "error";
                    const error = await response.json();
                    this.errorMessages = [error.message];
                    });
                } catch (error) {
                this.formState = "error";
                this.message = error.message;
                }
                return {}
            }    
        },
        handleFocus() {
            const that = this;
            setTimeout(() => {
                that.blurAllowed = true;
            }, 50);
        },
        handleBlur(cb) {
            if (!this.blurAllowed) return;
            if (cb && typeof cb === 'function') {
                cb();
            }
        },
        getErrorMessages(validationObject) {
            const errorMessages = [];
            Object.keys(validationObject).forEach(key => {
                if (key !== '$params' 
                    && key !== '$model' 
                    && typeof validationObject[key] === 'object' 
                ) {
                const messageObj = this.validationMessages[key].find(
                    message =>
                    !validationObject[key][message.key] &&
                    (validationObject[key].$error || validationObject[key].$invalid)
                );
                if (messageObj !== undefined) {
                    errorMessages.push(messageObj);
                }
                }
            });
            return errorMessages;
        },
        emitMesssages(validationObject, initialCall = false) {
            let validateErrorMessages = [];
            validateErrorMessages = this.getErrorMessages(validationObject);
            validateErrorMessages = [
                ...validateErrorMessages,
                ...this.getErrorMessages(validationObject.descriptions),
            ];
            if (!initialCall) {
                this.$emit('errors', validateErrorMessages);
            }
            this.$emit('validation', {
                isValid: !(validateErrorMessages.length > 0),
                isInitial: initialCall,
            });
        },
    },
    beforeMount() {
        //let json = JSON.stringify(this.$data);
        //Object.assign(this.$data, JSON.parse(json));
        this.getShipmodeInfo();
    },
    validations: {
        shipmodeobj:{
            shipMode: {
                required,
            },
            description: {
                required,
            },
            
            shipCharge: {
                required,
            },
            shipTax: {
                required,
            },
            transporter: {
                required,
            },
            trackingCode: {
                required,
            },
            markForDelete: {
                required,
            }, 
        },
    },
}

</script>
