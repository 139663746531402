import Polyglot from 'node-polyglot';
import resourceEnIn from './locales/en-in';

export default class I18n {
  constructor(locale = 'en-in') {
    this.polyglot = {      
      'en-in': new Polyglot({ phrases: resourceEnIn, locale: 'en-in' }),      
    }[locale];
  }

  localize(...args) {
    return this.polyglot.t(...args);
  }
}
