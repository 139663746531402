var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui form" }, [
      _c("div", { staticClass: "inline fields" }, [
        _c("label", [_vm._v("Type of Orders")]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("div", { staticClass: "ui radio checkbox" }, [
            _c("input", {
              attrs: { type: "radio", name: "frequency", checked: "checked" }
            }),
            _vm._v(" "),
            _c("label", [_vm._v("With Customer Order")])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("div", { staticClass: "ui radio checkbox" }, [
            _c("input", { attrs: { type: "radio", name: "frequency" } }),
            _vm._v(" "),
            _c("label", [_vm._v("Without Customer Order")])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fields" }, [
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("Order Number")]),
          _vm._v(" "),
          _c("input", {
            attrs: {
              type: "text",
              name: "order-number",
              placeholder: "Order Number"
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fields" }, [
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("Truck Number")]),
          _vm._v(" "),
          _c("input", {
            attrs: {
              type: "text",
              name: "truck-number",
              placeholder: "Truck Number"
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "ui button", attrs: { type: "submit" } }, [
        _vm._v("Search")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }