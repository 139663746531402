<template>
    <form>
        <div class="ui form">
            <div class="fields">
                <div class="field">                
                    <input-field
                        v-model.trim="product.partNumber"
                        :messages="validationMessages.partNumber"
                        :required="true"
                        :validation="$v.product.partNumber"    
                        :label="I18n('lblPartNumber')"
                        :placeholder="I18n('placeholderPartNumber')"                              
                        autocomplete="given-name"
                        name="partNumber"
                    /> 
                </div>
                <div class="field">
                    <input-field
                        v-model.trim="product.mfPartNumber"
                        :messages="validationMessages.mfPartNumber"
                        :required="true"
                        :validation="$v.product.mfPartNumber"    
                        :label="I18n('lblMfPartNumber')"
                        :placeholder="I18n('placeholderMfPartNumber')"                              
                        autocomplete="given-name"
                        name="mfPartNumber"
                    />  
                </div>
                <div class="field">
                    <input-field
                        v-model.trim="product.mfName"
                        :messages="validationMessages.mfName"
                        :required="true"
                        :validation="$v.product.mfName"    
                        :label="I18n('lblMfName')"
                        :placeholder="I18n('placeholderMfName')"                              
                        autocomplete="given-name"
                        name="mfName"
                    />  
                </div>            
            </div>
            <div class="fields">
                <div class="field">
                    <div class="ui checkbox">
                        <input
                            id="published"
                            v-model="productDesc.published"
                            :required="true"
                            name="published"
                            type="checkbox" >            
                        <label>Displayable</label>
                    </div>
                </div>
                <div class="field">
                    <div class="ui checkbox">
                        <input
                            id="markForDelete"
                            v-model="product.markForDelete"
                            :required="true"
                            name="markForDelete"
                            type="checkbox" >            
                        <label>For Delete</label>
                    </div>
                </div>             
                <div class="field">
                        <label>Available</label>
                        <div class="ui radio checkbox">
                        <input
                            v-model="productDesc.available"
                            :id="'_availableY'"
                            :name="'available'"
                            type="radio"
                            value="true"                            
                            class="hidden">
                        <label :for="'_availableY'">Yes</label>
                    </div>            
                    <div class="ui radio checkbox">
                        <input
                            v-model="productDesc.available"
                            :id="'_availableN'"
                            :name="'available'"
                            type="radio"
                            value="false"
                            class="hidden">
                        <label :for="'_availableN'">No</label>
                    </div>                
                </div>             
            </div>
            <div class="fields">
                <div class="field">                
                    <input-field
                        v-model.trim="productDesc.name"
                        :messages="validationMessages.name"
                        :required="true"
                        :validation="$v.productDesc.name"    
                        :label="I18n('lblName')"
                        :placeholder="I18n('placeholderProductName')"                              
                        autocomplete="given-name"
                        name="name"
                    /> 
                </div>
                <div class="field">                    
                    <input-field
                        v-model.trim="productDesc.keyword"
                        :messages="validationMessages.keyword"
                        :required="true"
                        :validation="$v.productDesc.keyword"    
                        :label="I18n('lblKeyword')"
                        :placeholder="I18n('placeholderProductKeyword')"                              
                        autocomplete="given-name"
                        name="keyword"
                    /> 
                </div>
                <div class="field">
                    <label>Type</label>
                    <select v-model="product.catEntType" class="ui field fluid select" id="product.catEntType"
                        :required="true"
                        :messages="validationMessages.type"
                        :validation="$v.product.catEntType">
                        <option disabled value="">Select..</option>
                        <option value="Product">Product</option>
                        <option value="Package">Package</option>                                      
                    </select>
                </div>                       
            </div>
            <div class="fields">
                <div class="field">
                    <textarea
                        :id="'productDesc.description'"
                        v-model.trim="productDesc.description"
                        rows="3"
                        :required="true"
                        :placeholder="I18n('placeholderProductDescription') "
                        @blur="$v.productDesc.description.$touch()"
                        @input="$v.productDesc.description.$reset()"/>                                                            
                        <validation-message
                        :field="$v.productDesc.description"
                        :errors-condition-texts="[
                            {condition: (f) => !f.required, text:  I18n('lblProductDescription') },
                        ]"
                        error-key="productDescription"
                        />
                </div>
            </div>
            <div class="fields">
                <div class="field">                
                    <input-field
                        v-model.number="stock.quantity" type="number" min="0" max="9999"
                        :messages="validationMessages.quantity"
                        :required="true"
                        :validation="$v.stock.quantity"    
                        :label="I18n('lblQuantity')"
                        :placeholder="I18n('placeholderProductQuantity')"                              
                        autocomplete="given-name"
                        name="quantity"
                    /> 
                </div>
                <div class="field">
                    <input-field
                        v-model.number="product.listPrice" type="number" min="0" max="99999"
                        :messages="validationMessages.listPrice"
                        :required="true"
                        :validation="$v.product.listPrice"    
                        :label="I18n('lblListPrice')"
                        :placeholder="I18n('placeholderProductListPrice')"                              
                        autocomplete="given-name"
                        name="listPrice"
                    />  
                </div>
            </div>
             <div class="fields">
                <div class="field">                
                    <input-field
                        v-model.trim="productDesc.thumbnail" 
                        :messages="validationMessages.thumbnail"
                        :required="false"
                        :validation="$v.productDesc.thumbnail"    
                        :label="I18n('lblThumbnail')"
                        :placeholder="I18n('placeholderProductThumbnail')"                              
                        autocomplete="given-name"
                        name="thumbnail"
                    /> 
                </div>
                <div class="field">
                    <input-field
                        v-model.trim="productDesc.fullImage" 
                        :messages="validationMessages.fullImage"
                        :required="false"
                        :validation="$v.productDesc.fullImage"    
                        :label="I18n('lblFullImage')"
                        :placeholder="I18n('placeholderProductFullImage')"                              
                        autocomplete="given-name"
                        name="fullImage"
                    />  
                </div>
            </div>
          
            <button
                id="add"
                :disabled="!isFormValid || formState === 'pending'"
                :class="{ 'loading disabled': formState === 'pending' }"
                class="ui primary button"
                type="submit"
                @click.prevent="addOrUpdateProduct()">{{ button.text }}</button>    
        </div>
    </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import catalogService from './catalog-service';
import validationMessages from '../common/forms/validation-messages';

import InputField from '../common/forms/input-field';
import ValidationIcon from '../common/forms/validation-icon';
import ValidationMessage from '../common/forms/validation-message';

export default {
    name : 'AddUpdateProduct',
    components: {    
        InputField,
        ValidationIcon,
        ValidationMessage,
    },
    data() {
        return {
            formState: undefined,
            message: undefined,
            product: {
                partNumber: undefined,
                mfPartNumber: undefined,
                mfName: undefined,
                catEntType:undefined,
                listPrice:undefined,
                markForDelete:undefined,
                descriptions:[],
                inventory:[]
            }, 
            productDesc: {
                name:undefined,
                description:undefined,
                keyword:undefined,
                available:undefined,
                published:undefined,
                thumbnail:undefined,
                fullImage:undefined,
            },
            stock:{
                quantity:undefined,
            },           
            validationMessages,
             button: {
                text: 'Add'
            },
        };
    },
    computed: {
    isFormValid() {
      return (
        this.product.partNumber &&         
        this.product.mfPartNumber && 
        this.product.mfName && 
        this.product.catEntType && 
        !this.$v.productDesc.name.$invalid &&
        !this.$v.productDesc.description.$invalid &&
        !this.$v.productDesc.keyword.$invalid &&
        //!this.$v.productDesc.thumbnail.$invalid &&
        //!this.$v.productDesc.fullImage.$invalid &&
        this.product.listPrice && 
        this.stock.quantity 
               
      );
    },
  },
    methods: {
        async addOrUpdateProduct() {
            this.message = undefined;
            this.formState = 'pending';
            var price =parseFloat(this.product.listPrice)
            this.product.listPrice = price
            this.product.descriptions.push(this.productDesc);
            var qtyNum=parseInt(this.stock.quantity)
            this.stock.quantity=qtyNum
            this.product.inventory.push(this.stock);
            //console.log(this.button.text);
            
            try {
                if(this.button.text =='Add'){
                     await catalogService.addProduct(this.product);
                }else{
                     await catalogService.updateProduct(this.product);
                }
                 this.formState = 'success';
                 this.message = '';
                 window.location.assign("/catalog/all-products");
            } catch (error) {
                this.formState = 'error';
                this.message = "Failed to Save Product Info";
            }
        },
         getProductInfo(){
            const me = this;
            if (me.$route) {
                var partNumber=me.$route.query[0];
                if(typeof partNumber == 'undefined'){
                    this.button.text='Add';
                    return false;
                }
                this.button.text='Update';
                this.formState = "pending";
                this.errorMessages = [];
                try {
                    return catalogService
                        .getProductByPartNumber(partNumber)
                        .then(response => {
                            this.formState = "success";
                            //console.log(JSON.stringify(response));
                            this.product.partNumber=response.product.partNumber;
                            this.product.mfPartNumber=response.product.mfPartNumber;
                            this.product.mfName=response.product.mfName;
                            this.product.catEntType=response.product.catEntType;
                            this.product.listPrice=response.product.listPrice.toString();
                            if(response.product.markForDelete ==1){
                                this.product.markForDelete=true    
                            }
                            this.$set(this.product,'catEntryId',response.product.catEntryId);
                            
                            this.productDesc.name=response.product.name;
                            this.productDesc.description=response.product.description;
                            this.productDesc.keyword=response.product.keyword;
                            this.productDesc.available=response.product.available;
                            this.productDesc.published=response.product.published;
                            this.productDesc.thumbnail=response.product.thumbnail;
                            this.productDesc.fullImage=response.product.fullImage;
                            this.stock.quantity=response.product.quantity.toString();

                            //console.log(JSON.stringify(this.product));
                        })
                    .catch(async response => {
                    this.formState = "error";
                    const error = await response.json();
                    this.errorMessages = [error.message];
                    });
                } catch (error) {
                this.formState = "error";
                this.message = error.message;
                }
                return {}
            }    
        },
        handleFocus() {
            const that = this;
            setTimeout(() => {
                that.blurAllowed = true;
            }, 50);
        },
        handleBlur(cb) {
            if (!this.blurAllowed) return;
            if (cb && typeof cb === 'function') {
                cb();
            }
        },
        getErrorMessages(validationObject) {
            const errorMessages = [];
            Object.keys(validationObject).forEach(key => {
                if (key !== '$params' 
                    && key !== '$model' 
                    && typeof validationObject[key] === 'object' 
                ) {
                const messageObj = this.validationMessages[key].find(
                    message =>
                    !validationObject[key][message.key] &&
                    (validationObject[key].$error || validationObject[key].$invalid)
                );
                if (messageObj !== undefined) {
                    errorMessages.push(messageObj);
                }
                }
            });
            return errorMessages;
        },
        emitMesssages(validationObject, initialCall = false) {
            let validateErrorMessages = [];
            validateErrorMessages = this.getErrorMessages(validationObject);
            validateErrorMessages = [
                ...validateErrorMessages,
                ...this.getErrorMessages(validationObject.descriptions),
            ];
            if (!initialCall) {
                this.$emit('errors', validateErrorMessages);
            }
            this.$emit('validation', {
                isValid: !(validateErrorMessages.length > 0),
                isInitial: initialCall,
            });
        },
    },
    beforeMount() {
        //let json = JSON.stringify(this.$data);
        //Object.assign(this.$data, JSON.parse(json));
        this.getProductInfo();
    },
    validations: {
        product:{
            partNumber: {
                required,
            },
            mfPartNumber: {
                required,
            },
            mfName: {
                required,
            },
            catEntType: {
                required,
            },
            listPrice: {
                required,
            },
            markForDelete: {
                required,
            }
        },
        productDesc :{
            langId: {
                required,
            },
            name: {
                required,
            },
            description: {
                required,
            },
            keyword: {
                required,
            },
            available: {
                required,
            },            
            published: {
                required,
            },
            thumbnail:{
                required,
            },
            fullImage:{
                required,
            }
        },
        stock:{
             quantity:{
                required,
            }
        }               
    },
}

</script>
