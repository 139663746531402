import I18n from '../../internationalization/i18n';
//import getBrandInfo from '../../common/brand-service';

let msg;

if (window.globalConfig !== undefined) {  
  msg = new I18n(window.globalConfig.language);  
} else {
  msg = new I18n('en-in');
}

export default {
  email: [
    { key: 'required', message: msg.localize('msgDontForgetEmail') },
    {
      key: 'email',
      message: msg.localize('msgEnterValidEmail'),
    },
  ],
  logonId: [
    { key: 'required', message: msg.localize('msgDontForgetEmailPlease') },
    {
      key: 'email',
      message: msg.localize('msgEnterValidEmail'),
    },
  ],
  username: [
    { key: 'required', message: msg.localize('msgDontForgetEmail') },
    {
      key: 'email',
      message: msg.localize('msgEnterValidEmail'),
    },
  ],
  password: [{ key: 'required', message: msg.localize('msgPwdPleaseEnter') }],  
  firstName: [
    { key: 'required', message: msg.localize('msgDontForgetFirstName') },
  ],  
  lastName: [
    { key: 'required', message: msg.localize('msgDontForgetLastName') },
  ],
  city: [{ key: 'required', message: msg.localize('msgDontForgetResidence') }],
  countryCode: [{ key: 'required', message: msg.localize('msgDontForgetCountryCode') }],
  phone1: [{ key: 'required', message: msg.localize('msgDontForgetPhoneNumber') }],
  address2: [{ key: 'required', message: msg.localize('msgDontForgetAddress2') }],
  address1: [{ key: 'required', message: msg.localize('msgDontForgetAddress1') }],
  zipCode: [{ key: 'required',message: msg.localize('msgDontForgetZipCode') }],
  state: [{ key: 'required', message: msg.localize('msgDontForgetState') }],
  title: [{ key: 'required', message: msg.localize('msgDontForgetGender') }],
  subject: [{ key: 'required', message: msg.localize('msgDontForgetSubject') }],
  comments: [{ key: 'required', message: msg.localize('msgDontForgetComments') }],
  //Product related
  partNumber: [{ key: 'required', message: msg.localize('msgDontForgetPartNumber') }],
  mfPartNumber: [{ key: 'required', message: msg.localize('msgDontForgetMfPartNumber') }],
  mfName: [{ key: 'required', message: msg.localize('msgDontForgetMfName') }],
  catEntType: [{ key: 'required', message: msg.localize('msgDontForgetCatEntType') }],
  listPrice: [{ key: 'required', message: msg.localize('msgDontForgetListPrice') }],
  quantity: [{ key: 'required', message: msg.localize('msgDontForgetQuantity') }],
  markForDelete: [{ key: 'required', message: msg.localize('msgDontForgetMarkForDelete') }],
  productDescription: [{ key: 'required', message: msg.localize('msgDontForgetProductDescription') }],
  langId:[{ key: 'required', message: msg.localize('msgDontForgetLangId') }],
  name:[{ key: 'required', message: msg.localize('msgDontForgetProductName') }],
  description:[{ key: 'required', message: msg.localize('msgDontForgetProductDescription') }],
  keyword:[{ key: 'required', message: msg.localize('msgDontForgetProductKeyword') }],
  available:[{ key: 'required', message: msg.localize('msgDontForgetProductAvailable') }],
  published:[{ key: 'required', message: msg.localize('msgDontForgetProductPublished') }],
  thumbNail:[{ key: 'required', message: msg.localize('msgDontForgetProductThumbnail') }],
  fullImage:[{ key: 'required', message: msg.localize('msgDontForgetProductFullImage') }],

  // Shipmode related
  
};
