import apiService from '../common/api-service';
import accountEvents from './sales-events';
import I18n from '../internationalization/i18n';

let msg;
if (window.globalConfig !== undefined) {
  msg = new I18n(window.globalConfig.language);
} else {
  msg = new I18n('en-in');
}

class SalesService{
        saveCustomer(contact) {
                return apiService.sales.saveCustomer(contact);
        }
        getCustomerList(){
                return apiService.sales.getCustomers();
        }
        getCustomerInfo(contactId){
                return apiService.sales.getCustomerInfo(contactId);
        }
        getCustomerInfoByName(name){
                return apiService.sales.getCustomerInfoByName(name);
        }
}


const salesService = new SalesService();
export default salesService;
