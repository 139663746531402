<template>
    <div class="ui form">
        <div class="inline fields">
            <label>Type of Orders</label>
            <div class="field">
                <div class="ui radio checkbox">
                    <input type="radio" name="frequency" checked="checked">
                    <label>With Customer Order</label>
                </div>
            </div>
            <div class="field">
                <div class="ui radio checkbox">
                    <input type="radio" name="frequency">
                    <label>Without Customer Order</label>
                </div>
            </div>            
        </div>
        <div class="fields">
            <div class="field">
                <label>Order Number</label>
                <input type="text" name="order-number" placeholder="Order Number">
            </div>
        </div>
        <div class="fields">
            <div class="field">
                <label>Truck Number</label>
                <input type="text" name="truck-number" placeholder="Truck Number">
            </div>
        </div>

        <button class="ui button" type="submit">Search</button>

    </div>  
    
</template>

<script>
export default {
    name : 'SalesByGeography',
}
</script>
