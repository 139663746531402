<template>
    <div class="ui form">
        <div class="fields">
            <div class="field">
                <label>First name</label>
                <input type="text" placeholder="First Name">
            </div>
            <div class="field">
                <label>Middle name</label>
                <input type="text" placeholder="Middle Name">
            </div>
            <div class="field">
                <label>Last name</label>
                <input type="text" placeholder="Last Name">
            </div>            
        </div>
         <div class="fields">
            <div class="field">
                <label>Gender</label>
                <div class="ui selection dropdown">
                    <input type="hidden" name="gender">
                    <i class="dropdown icon"></i>
                    <div class="default text">Gender</div>
                    <div class="menu">
                        <div class="item" data-value="1">Male</div>
                        <div class="item" data-value="0">Female</div>
                    </div>
                </div>
            </div>
            <div class="field">
                <label>Phone Number</label>
                <div class="field">                
                    <input type="text" placeholder="xxxx">
                </div>
            </div>
         </div>
         <div class="fields">
            <div class="field">
                <label>City</label>
                <input type="text" placeholder="City">
            </div>
            <div class="field">
                <label>State</label>
                <input type="text" placeholder="State">
            </div>
            <div class="field">
                <label>Country</label>
                <input type="text" placeholder="Country">
            </div>            
        </div>
    </div>
    
</template>

<script>
    export default {
        name : 'FindCustomer',
    } 
</script>
