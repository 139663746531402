/* eslint-disable class-methods-use-this */

import apiService from '../common/api-service';
//import catalogEvents from './catalog-events';
import I18n from '../internationalization/i18n';

let msg;
if (window.globalConfig !== undefined) {
  msg = new I18n(window.globalConfig.language);
} else {
  msg = new I18n('en-in');
}

class ShipmodeService {
  addShipmode(shipmode) {
    return apiService.shipmode.addShipmode(shipmode);
  }
  
  updateShipmode(shipmode){
    return apiService.shipmode.updateShipmode(shipmode);
  }
  
  getShipmodes(){
    return apiService.shipmode.getShipmodes();
  }
  
  getShipmodeByName(shipmodeName){
        return apiService.shipmode.getShipmodeByName(shipmodeName);
  }
}

const shipmodeService = new ShipmodeService();
export default shipmodeService;

/* eslint-enable class-methods-use-this */
