var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.messages && _vm.messages.length
    ? _c("div", { staticClass: "ui icon message", class: _vm.type }, [
        _c("i", { staticClass: "circular warning icon" }),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "header" },
            _vm._l(_vm.messages, function(message) {
              return _c("p", { key: message }, [
                _vm._v("\n        " + _vm._s(message) + "\n        "),
                _vm.needErrorLink
                  ? _c("a", { attrs: { href: _vm.errorLinkUrl } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.errorLinkMessage) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ])
            })
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }