<template>
    	<div class="contact">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <h4><strong>Get in touch with us filling the information below </strong></h4>
              <form id="contactform" action="" method="post" name="send-contact">
                <div class="row">
                  <div class="col-lg-4 field">
                    <input-field
                      v-model.trim="firstName"
                      :messages="validationMessages.firstName"
                      :required="true"
                      :validation="$v.firstName"    
                      :label="I18n('lblName')"
                      :placeholder="I18n('placeholderFirstName')"                              
                      autocomplete="given-name"
                      name="firstName"
                    />
                    <!--validation-icon :field="$v.firstName"/-->
                  </div>   
                  <div class="col-lg-4 field">
                      <input-field
                        v-model.trim="email"
                        :messages="validationMessages.email"
                        :required="true"
                        :validation="$v.email"    
                        :label="I18n('lblEmail')"
                        :placeholder="I18n('placeholderEmail1')"                              
                        autocomplete="email"
                        name="email"
                      />                 
                    </div>  
                    <div class="col-lg-4 field">
                      <input-field
                        v-model.trim="subject"
                        :messages="validationMessages.subject"
                        :required="true"
                        :validation="$v.subject"    
                        :label="I18n('lblSubject')"
                        :placeholder="I18n('placeholderSubject')"                              
                        autocomplete="off"
                        name="subject"
                      />    
                    </div>  
                    <div class="col-lg-12 margintop10 field">
                       <textarea
                        :id="'comments'"
                        v-model.trim="comments"
                        rows="3"
                        :required="true"
                        :placeholder="I18n('placeholderComments') "
                        @blur="$v.comments.$touch()"
                        @input="$v.comments.$reset()"/>
                      <!--validation-icon :field="$v.comments"/-->                      
                      <validation-message
                        :field="$v.comments"
                        :errors-condition-texts="[
                          {condition: (f) => !f.required, text:  I18n('lblComments') },
                        ]"
                        error-key="comments"
                      />
                      <div>
                        <button 
                          id="sendemail"
                          :disabled="!isFormValid || formState === 'pending'"
                          :class="{ 'loading disabled': formState === 'pending' }"
                          class="btn btn-theme margintop10 pull-left" 
                          style="display:inline-block;"                           
                          type="submit"  
                          @click.prevent="sendEmail()">Submit message</button>
                          <div v-if="formState == 'success'"
                              v-cloak
                              style="display:inline-block;margin-left:5px;padding-left:5px;" id="results">
                                {{ message }}
                          </div>

                      </div>
                    </div>           
                </div>
              </form>
            </div>
          </div>
        </div>
    	</div>			
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import validationMessages from '../common/forms/validation-messages';
import registerValidationNeededMixin from './register-validation-needed-mixin';
import accountService from './account-service';

import InputField from '../common/forms/input-field';
import ValidationIcon from '../common/forms/validation-icon';
import ValidationMessage from '../common/forms/validation-message';

export default {
  name: 'ContactUs', 
  mixins: [registerValidationNeededMixin],
  data() {
    return {
      formState: undefined,
      message: undefined, 
      firstName: undefined,  
      email: undefined,   
      subject: undefined,
      comments:undefined,      
      validationMessages,      
    };
  },
  components: {    
    InputField,
    ValidationIcon,
    ValidationMessage,      
  },
  methods: {
    async sendEmail(){
      this.message = undefined;
      this.formState = 'pending'; 
      try {
          await accountService.sendEmail(firstName.value, email.value, subject.value, comments.value)
          this.formState = 'success';
          this.message = 'Email Sent Successfully';  
          //resetMessage();
      } catch (error) {
          this.formState = 'error';
          this.message = "Error Sending the email, Please try later.";
      }
    },    
    handleFocus() {
        const that = this;
        this.message = undefined;
        setTimeout(() => {
            that.blurAllowed = true;
        }, 50);
    },
    handleBlur(cb) {
        this.message = undefined;
        if (!this.blurAllowed) return;
        if (cb && typeof cb === 'function') {
            cb();
        }
    },
    getErrorMessages(validationObject) {
        const errorMessages = [];
        Object.keys(validationObject).forEach(key => {
            if (
            key !== 'address' &&
            key !== '$params' &&
            key !== '$model' &&
            typeof validationObject[key] === 'object' &&
            !((key === 'city' || key === 'street') && !this.hasCheckedAddress)
            ) {
            const messageObj = this.validationMessages[key].find(
                message =>
                !validationObject[key][message.key] &&
                (validationObject[key].$error || validationObject[key].$invalid)
            );
            if (messageObj !== undefined) {
                errorMessages.push(messageObj);
            }
            }
        });
        return errorMessages;
    },
    emitMesssages(validationObject, initialCall = false) {
        let validateErrorMessages = [];
        validateErrorMessages = this.getErrorMessages(validationObject);
        validateErrorMessages = [
            ...validateErrorMessages,
            ...this.getErrorMessages(validationObject.address),
        ];
        if (!initialCall) {
            this.$emit('errors', validateErrorMessages);
        }
        this.$emit('validation', {
            isValid: !(validateErrorMessages.length > 0),
            isInitial: initialCall,
        });
    },
    resetMessage() {    
     this.firstName.value = '';
     this.email.value = '';
     this.subject.value = '';
     this.comments.value = '';          
    },
  }, 
  computed: {
    isFormValid() {
      return (         
        !this.$v.firstName.$invalid &&
        !this.$v.email.$invalid &&
        !this.$v.subject.$invalid &&
        !this.$v.comments.$invalid 
      );
    },
  }, 
validations: {
    firstName: {
          required,
      },        
    email:{
      required,
    },
    subject: {
      required,
    },
    comments: {
      required,
    },
  },              
 
};
</script>