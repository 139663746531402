import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueResource from 'vue-resource';
import VueRouter from 'vue-router';
import Vuex from 'vuex';

function forbiddenRouteGuard(to, from, next) {
  /*if (!accountService.currentLoginId()) {
    window.store.commit('onAppError', 403);
  }*/
  next();
}

// Serverside rendered components
import langSubString from './common/multi-lang-service';
import I18n from './internationalization/i18n';
import I18nVueMixin from './internationalization/i18n-mixin';
import ValidationMessage from './common/forms/validation-message';

import LoginForm from './home/login-form';
import LogoutForm from './home/logout-form';
import LoginListItem from './home/login-list-item';
import RegisterForm from './home/register-form';
import ContactUs from './home/contact-us';
import ContentRoot from './content-root';
import FindCustomer from './sales/find-customer';
import AllCustomers from './sales/all-customers';
import AddUpdateCustomer from './sales/add-update-customer';
import FindOrder from './sales/find-order';
import SalesByProduct from './reports/sales-by-product';
import SalesByGeography from './reports/sales-by-geography';
import ReceivablesByGeography from './reports/receivables-by-geography';
import AllProducts from './catalog/all-products';
import AddUpdateProduct from './catalog/add-update-product';
import ProductList from './catalog/product-list';
import ProductDetail from './catalog/product-detail';
import Shipmodes from './shipping/shipmodes';
import AddUpdateShipmode from './shipping/add-update-shipmode';
import Cart from './cart/cart';

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: `${langSubString()}/sales/find-customer`,
      component: FindCustomer,
      beforeEnter: forbiddenRouteGuard,
    },
    {
      path: `${langSubString()}/sales/add-update-customer`,
      component: AddUpdateCustomer,
      beforeEnter: forbiddenRouteGuard,
    },
    {
      path: `${langSubString()}/sales/all-customers`,
      component: AllCustomers,
      beforeEnter: forbiddenRouteGuard,
    },
    
    {
      path: `${langSubString()}/sales/find-order`,
      component: FindOrder,
      beforeEnter: forbiddenRouteGuard,
    },
    {
      path: `${langSubString()}/reports/sales-by-product`,
      component: SalesByProduct,
      beforeEnter: forbiddenRouteGuard,
    },
    {
      path: `${langSubString()}/reports/sales-by-geography`,
      component: SalesByGeography,
      beforeEnter: forbiddenRouteGuard,
    },
    {
      path: `${langSubString()}/reports/receivables-by-geography`,
      component: ReceivablesByGeography,
      beforeEnter: forbiddenRouteGuard,
    },
    {
      path: `${langSubString()}/catalog/all-products`,
      component: AllProducts,
      beforeEnter: forbiddenRouteGuard,
    },
    {
      path: `${langSubString()}/catalog/add-update-product`,
      component: AddUpdateProduct,
      beforeEnter: forbiddenRouteGuard,
    },
    {
      path: `${langSubString()}/shipping/shipmodes`,
      component: Shipmodes,
      beforeEnter: forbiddenRouteGuard,
    },
    {
      path: `${langSubString()}/shipping/add-update-shipmode`,
      component: AddUpdateShipmode,
      beforeEnter: forbiddenRouteGuard,
    },
    {
      path: `${langSubString()}/catalog/product-list`,
      component: ProductList,
      beforeEnter: forbiddenRouteGuard,
    },
    {
      path: `${langSubString()}/catalog/product-detail`,
      component: ProductDetail,
      beforeEnter: forbiddenRouteGuard,
    },
    {
      path: `${langSubString()}/cart/cart`,
      component: Cart,
      beforeEnter: forbiddenRouteGuard,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (window.globalConfig && window.globalConfig.multilingual) {
    if (to.matched.length > 0) {
      const path = to.fullPath.replace(/\/(nl|fr)\//, '');
      window.store.dispatch('common/overrideAlternatesWithLink', {
        queryPart: path,
      });
      next();
    }
  }
  next();
});

window.I18n = new I18n('en-in');

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(VueResource);
Vue.use(VueRouter);
Vue.use(Vuex);

Vue.mixin(I18nVueMixin);

if (document.querySelector('#vue-root')) {
  new Vue({
    components: {
      LoginForm,  
      LogoutForm,
      LoginListItem,
      ContentRoot,
      RegisterForm,
      ContactUs,
      ValidationMessage,
      ProductList,
      ProductDetail,
      Cart,
    },
    router,
  }).$mount('#vue-root');
}
