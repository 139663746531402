<template>
  <div
    :class="{ error: validation.$error, required: required }"
    class="field">
    <label :for="name">{{ label }}<span
      v-if="tooltipText"
      :data-tooltip="tooltipText">
      <i class="help circle outline icon link" />
    </span></label>

    <div class="ui icon input">
      <input
        :autocomplete="autocomplete"
        :value="value"
        :name="name"
        :id="name"
        :placeholder="placeholder"
        :type="type"
        @blur="validation.$touch(); blur($event); updatePasswordTouched(); emitMesssages(validation, $event.target.name)"
        @input="updateValue($event.target.value); validation.$reset()">
      <validation-icon :field="validation"/>
    </div>

    <div
      v-if="validation.$error && visibleMessage"
      class="message">{{ visibleMessage }}</div>
  </div>
</template>

<script>
import ValidationIcon from './validation-icon';
import validationMessages from '../../common/forms/validation-messages';

export default {
  name: 'InputField',
  components: {
    ValidationIcon,
  },
  props: {
    autocomplete: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    messages: {
      type: Array,
      default() {
        return [];
      },
    },
    name: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      default: undefined,
    },
    tooltipInverted: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: String,
      default: undefined,
    },
    validation: {
      type: Object,
      required: true,
    },
    passwordTouched: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validationMessages,
    };
  },
  computed: {
    visibleMessage() {
      // Only show the first error message
      const messageObj = this.messages.find(
        message => !this.validation[message.key]
      );

      return messageObj && messageObj.message;
    },
    isTouchedPassword() {
      return !(
        this.passwordTouched &&
        this.name === 'signUpPassword' &&
        this.validation.$dirty
      );
    },
  },
  watch: {
    validationResultsNeeded() {
      this.validation.$touch();
      this.emitMesssages(this.validation, this.name);
    },
  },
  mounted() {
    if (this.tooltipInverted) {
      this.$el
        .querySelector('[data-tooltip]')
        .setAttribute('data-inverted', '');
    }
  },
  methods: {
    emitMesssages(val, eventName) {
      // For only password input fields
      if (!this.isTouchedPassword) {
        return;
      }
      const errorMessages = [];
      let temp = true;
      Object.keys(val).forEach(key => {
        if (key === 'minLength' || key === 'password' || key === 'sameAs') {
          if (!val[key]) {
            const messageObj = this.validationMessages[eventName].find(
              message => key === message.key
            );
            if (messageObj !== undefined && temp) {
              errorMessages.push(messageObj);
              temp = false;
            }
          }
        }
      });
      this.$emit('errors', errorMessages);
    },
    updateValue(val) {
      this.$emit('input', val);
    },
    blur(e) {
      this.$emit('blur', e);
    },
    displayToolTip() {
      this.tooltipIsActive = !this.tooltipIsActive;
    },
    updatePasswordTouched() {
      this.$emit('passwordTouched');
    },
  },
};
</script>
