<template>
  <div
    v-if="messages && messages.length"
    :class="type"
    class="ui icon message">
    <i class="circular warning icon"/>
    <div class="content">
      <div class="header">
        <p
          v-for="message in messages"
          :key="message">
          {{ message }}
          <a
            v-if="needErrorLink"
            :href="errorLinkUrl">
            {{ errorLinkMessage }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage',
  props: {
    messages: {
      type: Array,
      default: undefined,
    },
    type: {
      type: String,
      default: 'error',
    },
    needErrorLink: {
      type: Boolean,
      default: false,
    },
    errorLinkUrl: {
      type: String,
      default: '',
    },
    errorLinkMessage: {
      type: String,
      default: '',
    },
  },
};
</script>
