var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui container home" }, [
    _c("h4", { staticClass: "ui dividing header" }, [_vm._v("Customers")]),
    _vm._v(" "),
    _c("div", { staticClass: "alignGrid" }, [
      _c(
        "div",
        { staticClass: "ui two column grid" },
        _vm._l(_vm.CustomerList.allCustomers, function(p) {
          return _c(
            "div",
            {
              key: p.addressId,
              ref: "customerDiv_" + p.addressId,
              refInFor: true,
              staticClass: "column",
              attrs: { id: "customerDiv_" + p.addressId }
            },
            [
              _c("div", { staticClass: "ui card" }, [
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "header" }, [
                    _vm._v(_vm._s(p.title) + "." + _vm._s(p.firstName))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description" }, [
                    _vm._v("Lastname : " + _vm._s(p.lastName))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description" }, [
                    _vm._v("Address 1 : " + _vm._s(p.address1))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description" }, [
                    _vm._v("Address 2 : " + _vm._s(p.address2))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description" }, [
                    _vm._v("Email    : " + _vm._s(p.email))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description" }, [
                    _vm._v("City    : " + _vm._s(p.city))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description" }, [
                    _vm._v("Zip    : " + _vm._s(p.zipCode))
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            _vm.toEdit(p.addressId)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "ui blue submit button" }, [
                          _vm._v("Update")
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]
          )
        })
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }