var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "ui form" }, [
    _c("h4", { staticClass: "ui dividing header" }, [
      _vm._v(_vm._s(_vm.I18n("headerRegistration")))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "field" }, [
        _c("label", [_vm._v(_vm._s(_vm.I18n("lblSalutation")))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "inline fields" }, [
        _c("div", { staticClass: "field" }, [
          _c("div", { staticClass: "ui radio checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.contact.title,
                  expression: "contact.title"
                }
              ],
              staticClass: "hidden",
              attrs: {
                id: "_titleM",
                name: "title",
                type: "radio",
                value: "M"
              },
              domProps: { checked: _vm._q(_vm.contact.title, "M") },
              on: {
                change: function($event) {
                  _vm.$set(_vm.contact, "title", "M")
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "_titleM" } }, [
              _vm._v(_vm._s(_vm.I18n("lblGenderMale")))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("div", { staticClass: "ui radio checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.contact.title,
                  expression: "contact.title"
                }
              ],
              staticClass: "hidden",
              attrs: {
                id: "_titleF",
                name: "title",
                type: "radio",
                value: "F"
              },
              domProps: { checked: _vm._q(_vm.contact.title, "F") },
              on: {
                change: function($event) {
                  _vm.$set(_vm.contact, "title", "F")
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "_titleF" } }, [
              _vm._v(_vm._s(_vm.I18n("lblGenderFemale")))
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "two fields" }, [
        _c("div", { staticClass: "field" }, [
          _c(
            "div",
            {
              staticClass: "required field",
              class: {
                error:
                  _vm.$v.contact.address.firstName.$error ||
                  (_vm.$v.contact.address.firstName.$invalid &&
                    _vm.validationResultsNeeded),
                validated:
                  _vm.$v.contact.address.firstName.$dirty &&
                  !_vm.$v.contact.address.firstName.$invalid
              }
            },
            [
              _c("label", { attrs: { for: "firstname" } }, [
                _vm._v(_vm._s(_vm.I18n("lblFirstName")))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ui icon input" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.contact.address.firstName,
                        expression: "contact.address.firstName",
                        modifiers: { trim: true }
                      }
                    ],
                    attrs: {
                      id: "contact.address.firstName",
                      placeholder: _vm.I18n("placeholderFirstName"),
                      name: "firstname",
                      "data-test": "firstname",
                      type: "text",
                      autocomplete: "given-name"
                    },
                    domProps: { value: _vm.contact.address.firstName },
                    on: {
                      focus: function($event) {
                        _vm.handleFocus()
                      },
                      blur: [
                        function($event) {
                          _vm.handleBlur(function() {
                            _vm.$v.contact.address.firstName.$touch()
                            _vm.emitMesssages(_vm.$v.contact)
                          })
                        },
                        function($event) {
                          _vm.$forceUpdate()
                        }
                      ],
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.contact.address,
                            "firstName",
                            $event.target.value.trim()
                          )
                        },
                        function($event) {
                          _vm.$v.contact.address.firstName.$reset()
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("validation-icon", {
                    attrs: { field: _vm.$v.contact.address.firstName }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.$v.contact.address.firstName.$error ||
              (_vm.$v.contact.address.firstName.$invalid &&
                _vm.validationResultsNeeded)
                ? _c("div", { staticClass: "message" }, [
                    _vm._v(_vm._s(_vm.I18n("msgDontForgetFirstName")))
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c(
            "div",
            {
              staticClass: "required field",
              class: {
                error:
                  _vm.$v.contact.address.lastName.$error ||
                  (_vm.$v.contact.address.lastName.$invalid &&
                    _vm.validationResultsNeeded),
                validated:
                  _vm.$v.contact.address.lastName.$dirty &&
                  !_vm.$v.contact.address.lastName.$invalid
              }
            },
            [
              _c("label", { attrs: { for: "lastName" } }, [
                _vm._v(_vm._s(_vm.I18n("lblLastName")))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ui icon input" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.contact.address.lastName,
                        expression: "contact.address.lastName",
                        modifiers: { trim: true }
                      }
                    ],
                    attrs: {
                      id: "contact.address.lastName",
                      placeholder: _vm.I18n("placeholderLastName"),
                      name: "lastname",
                      "data-test": "lastname",
                      type: "text",
                      autocomplete: "given-name"
                    },
                    domProps: { value: _vm.contact.address.lastName },
                    on: {
                      focus: function($event) {
                        _vm.handleFocus()
                      },
                      blur: [
                        function($event) {
                          _vm.handleBlur(function() {
                            _vm.$v.contact.address.lastName.$touch()
                            _vm.emitMesssages(_vm.$v.contact)
                          })
                        },
                        function($event) {
                          _vm.$forceUpdate()
                        }
                      ],
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.contact.address,
                            "lastName",
                            $event.target.value.trim()
                          )
                        },
                        function($event) {
                          _vm.$v.contact.address.lastName.$reset()
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("validation-icon", {
                    attrs: { field: _vm.$v.contact.address.lastName }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.$v.contact.address.lastName.$error ||
              (_vm.$v.contact.address.lastName.$invalid &&
                _vm.validationResultsNeeded)
                ? _c("div", { staticClass: "message" }, [
                    _vm._v(_vm._s(_vm.I18n("msgDontForgetLastName")))
                  ])
                : _vm._e()
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "fields" }, [
        _c("div", { staticClass: "twelve wide field" }, [
          _c(
            "div",
            {
              staticClass: "required field",
              class: {
                error:
                  _vm.$v.contact.address.address1.$error ||
                  (_vm.$v.contact.address.address1.$invalid &&
                    _vm.validationResultsNeeded),
                validated:
                  _vm.$v.contact.address.address1.$dirty &&
                  !_vm.$v.contact.address.address1.$invalid
              }
            },
            [
              _c("label", { attrs: { for: "address1" } }, [
                _vm._v(_vm._s(_vm.I18n("lblAddress1")))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ui icon input" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.contact.address.address1,
                        expression: "contact.address.address1",
                        modifiers: { trim: true }
                      }
                    ],
                    attrs: {
                      id: "contact.address.address1",
                      placeholder: _vm.I18n("placeholderAddress1"),
                      name: "address1",
                      "data-test": "address1",
                      type: "text",
                      autocomplete: "given-name"
                    },
                    domProps: { value: _vm.contact.address.address1 },
                    on: {
                      focus: function($event) {
                        _vm.handleFocus()
                      },
                      blur: [
                        function($event) {
                          _vm.handleBlur(function() {
                            _vm.$v.contact.address.address1.$touch()
                            _vm.emitMesssages(_vm.$v.contact)
                          })
                        },
                        function($event) {
                          _vm.$forceUpdate()
                        }
                      ],
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.contact.address,
                            "address1",
                            $event.target.value.trim()
                          )
                        },
                        function($event) {
                          _vm.$v.contact.address.address1.$reset()
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("validation-icon", {
                    attrs: { field: _vm.$v.contact.address.address1 }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.$v.contact.address.address1.$error ||
              (_vm.$v.contact.address.address1.$invalid &&
                _vm.validationResultsNeeded)
                ? _c("div", { staticClass: "message" }, [
                    _vm._v(_vm._s(_vm.I18n("msgDontForgetAddress1")))
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "four wide field" }, [
          _c(
            "div",
            {
              staticClass: "required field",
              class: {
                error:
                  _vm.$v.contact.address.address2.$error ||
                  (_vm.$v.contact.address.address2.$invalid &&
                    _vm.validationResultsNeeded),
                validated:
                  _vm.$v.contact.address.address2.$dirty &&
                  !_vm.$v.contact.address.address2.$invalid
              }
            },
            [
              _c("label", { attrs: { for: "address2" } }, [
                _vm._v(_vm._s(_vm.I18n("lblAddress2")))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ui icon input" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.contact.address.address2,
                        expression: "contact.address.address2",
                        modifiers: { trim: true }
                      }
                    ],
                    attrs: {
                      id: "contact.address.address2",
                      placeholder: _vm.I18n("placeholderAddress2"),
                      name: "address2",
                      "data-test": "address2",
                      type: "text",
                      autocomplete: "given-name"
                    },
                    domProps: { value: _vm.contact.address.address2 },
                    on: {
                      focus: function($event) {
                        _vm.handleFocus()
                      },
                      blur: [
                        function($event) {
                          _vm.handleBlur(function() {
                            _vm.$v.contact.address.address2.$touch()
                            _vm.emitMesssages(_vm.$v.contact)
                          })
                        },
                        function($event) {
                          _vm.$forceUpdate()
                        }
                      ],
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.contact.address,
                            "address2",
                            $event.target.value.trim()
                          )
                        },
                        function($event) {
                          _vm.$v.contact.address.address2.$reset()
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("validation-icon", {
                    attrs: { field: _vm.$v.contact.address.address2 }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.$v.contact.address.address2.$error ||
              (_vm.$v.contact.address.address2.$invalid &&
                _vm.validationResultsNeeded)
                ? _c("div", { staticClass: "message" }, [
                    _vm._v(_vm._s(_vm.I18n("msgEnterValidAddress2")))
                  ])
                : _vm._e()
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "two fields" }, [
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("input-field", {
              attrs: {
                messages: _vm.validationMessages.username,
                required: true,
                validation: _vm.$v.contact.logonId,
                label: _vm.I18n("lblEmail"),
                placeholder: _vm.I18n("placeholderEmail"),
                autocomplete: "email",
                name: "logonId"
              },
              model: {
                value: _vm.contact.logonId,
                callback: function($$v) {
                  _vm.$set(
                    _vm.contact,
                    "logonId",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "contact.logonId"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("input-field", {
              attrs: {
                type: "number",
                messages: _vm.validationMessages.phone1,
                required: false,
                validation: _vm.$v.contact.address.phone1,
                label: _vm.I18n("lblPhone1"),
                placeholder: _vm.I18n("placeholderPhone1"),
                autocomplete: "phone1",
                name: "phone1"
              },
              model: {
                value: _vm.contact.address.phone1,
                callback: function($$v) {
                  _vm.$set(
                    _vm.contact.address,
                    "phone1",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "contact.address.phone1"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "two fields" }, [
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("input-field", {
              attrs: {
                messages: _vm.validationMessages.password,
                required: true,
                validation: _vm.$v.contact.password,
                label: _vm.I18n("lblPwd"),
                placeholder: _vm.I18n("lblPwd"),
                autocomplete: "off",
                name: "password",
                type: "password"
              },
              model: {
                value: _vm.contact.password,
                callback: function($$v) {
                  _vm.$set(_vm.contact, "password", $$v)
                },
                expression: "contact.password"
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "ui checkbox" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.agree,
              expression: "agree"
            }
          ],
          attrs: {
            id: "agree",
            required: true,
            name: "agree",
            type: "checkbox"
          },
          domProps: {
            checked: Array.isArray(_vm.agree)
              ? _vm._i(_vm.agree, null) > -1
              : _vm.agree
          },
          on: {
            change: function($event) {
              var $$a = _vm.agree,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.agree = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.agree = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.agree = $$c
              }
            }
          }
        }),
        _vm._v(" "),
        _c("label", [_vm._v("I agree to the Terms and Conditions")])
      ])
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "ui primary button",
        class: { "loading disabled": _vm.formState === "pending" },
        attrs: {
          id: "register",
          disabled: !_vm.isFormValid || _vm.formState === "pending",
          type: "submit"
        },
        on: {
          click: function($event) {
            $event.preventDefault()
            _vm.registerUser()
          }
        }
      },
      [_vm._v("Submit")]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "two fields" }, [
      _c("div", { staticClass: "field" }, [
        _c("label", [_vm._v("State")]),
        _vm._v(" "),
        _c("select", { staticClass: "ui field fluid select" }, [
          _c("option", { attrs: { value: "" } }, [_vm._v("State")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "KA" } }, [_vm._v("Karnataka")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "AP" } }, [_vm._v("AndhraPradesh")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "TN" } }, [_vm._v("Tamilnadu")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "KL" } }, [_vm._v("Kerala")])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        _c("label", [_vm._v("Country")]),
        _vm._v(" "),
        _c("select", { staticClass: "ui field fluid select" }, [
          _c("option", { attrs: { value: "" } }, [_vm._v("Select Country")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "IN" } }, [_vm._v("India")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }