<template>
   <div class="ui container product cart">
      <div class="alignGrid">
         <div class="ui header">Shopping Cart</div>
         <div class="ui five column grid header-title">
            <div class="column"></div>
            <div class="column">Name</div>
            <div class="column">Price</div>
            <div class="column">Quantity</div>
            <div class="column right aligned">
               <div class="ui price total">Total</div>
            </div>
         </div>

         <div class="ui five column grid" v-for="cartItem in CartInfo.orderItems"
                :key="cartItem.orderItemId"
                :id = "'orderItemtDiv_'+cartItem.orderItemId"
                :ref= "'orderItemtDiv_'+cartItem.orderItemId" >
            <div class="column" >
               <div class="ui card">
                  <div class="image">
                     <img :src="cartItem.thumbnail" />
                  </div>
               </div>
            </div>
            <div class="column">
               <div class="ui card">
                  <div class="header">{{cartItem.name}}</div>
               </div>
            </div>
            <div class="column">
               <div class="ui card">
                  <div class="ui price">{{cartItem.price}}</div>
               </div>
            </div>
            <div class="column">
               <div class="ui card" id="quantitySelect">
                  <select class="ui dropdown">
                     <option value="1">1</option>
                     <option value="2">2</option>
                     <option value="3">3</option>
                     <option value="4">4</option>
                     <option value="5">5</option>
                     <option value="6">6</option>
                     <option value="7">7</option>
                     <option value="8">8</option>
                     <option value="9">9</option>
                     <option value="10">10</option>
                  </select>
               </div>
            </div>
            <div class="column">
               <div class="ui card">
                  <div class="ui price total right aligned">{{cartItem.totalProduct}}</div>
               </div>
            </div>
         </div>
        
         <div class="ui secondary zero margin segment">
            <div class="ui stackable grid">
               <div class="eight wide column blank"></div>
               <div class="four wide column orderTotals">
                  <table class="ui compact no-border unstackable right aligned summed table">
                     <tbody>
                        <tr class="underlined">
                           <td scope="row" class="right aligned">Sub total</td>
                           <td class="ui five wide nowrap">{{CartInfo.totalProduct}}</td>
                        </tr>
                        <tr class="underlined">
                           <td scope="row" class="right aligned">Discount</td>
                           <td class="ui five wide nowrap">0.00</td>
                        </tr>
                        <tr class="underlined">
                           <td scope="row" class="right aligned">Shipping</td>
                           <td class="ui five wide nowrap">{{CartInfo.totalShipping}}</td>
                        </tr>
                        <tr>
                           <td scope="row" class="right aligned">
                              <strong>Total</strong>
                           </td>
                           <td class="ui five wide nowrap">
                              <strong>{{CartInfo.totalCart}}</strong>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
         <div class="ui two column grid">
            <div class="column">
               <div class="searchUser"><h5 class="ui dividing header">Search Customer</h5>
                  <div class="ui search">
                     <div class="ui icon input">
                        <input v-model.trim="customer.name" class="prompt" type="text" placeholder="By first name / last name" />
                         <a href="#" v-on:click="searchCustomer()">
                               <i class="search icon"></i>
                         </a>
                     </div>
                     <div class="results transition visible" style="display: block !important;" v-for="p in CustomerList.allCustomers"
                        :key="p.addressId"
                        :id = "'customerDiv_'+p.userId"
                        :ref= "'customerDiv_'+p.userId">
                        <a class="result" href="#" v-on:click="showCustomer(p)">
                           <div class="content">
                              <div class="title">{{p.firstName}} {{p.firstName}}</div>
                           </div>
                        </a>
                     </div>
                  </div>
               </div>
            </div>
            <div class="column searchUserDetails">
               <table class="ui basic table">
                  <tbody>
                     <tr>
                        <td>
                           <strong>First Name</strong>
                        </td>
                        <td v-html="custFirstName"></td>
                     </tr>
                     <tr>
                        <td>
                           <strong>Last Name</strong>
                        </td>
                        <td v-html="custLastName"></td>
                     </tr>
                     <tr>
                        <td>
                           <strong>Email</strong>
                        </td>
                        <td v-html="custEmail"></td>
                     </tr>
                     <tr>
                        <td>
                           <strong>Phone</strong>
                        </td>
                        <td v-html="custPhone"></td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
         <div class="ui blue submit button custom">Place Order</div>
      </div>
   </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import cartService from "../cart/cart-service";
import salesService from '../sales/sales-service';
import InputField from '../common/forms/input-field';
import validationMessages from "../common/forms/validation-messages";
import ErrorMessage from "../error/error-message";
export default {
       name: "Cart",
        components: {
                ErrorMessage
        },
        data() {
          return {
            CartInfo: undefined,
            customer:{
                    name:undefined,
            },
             CustomerList: [],
             custFirstName:undefined,
             custLastName:undefined,
             custEmail:undefined,
             custPhone:undefined,
             customerId:undefined,
          };
        },
        methods:{
                getCartForUser(){
                    this.formState = "pending";
                    this.errorMessages = [];
                    try {
                        return cartService
                          .getCurrentCart()
                          .then(response => {
                            this.formState = "success";
                            this.CartInfo = response;
                            var totalProduct=parseFloat(this.CartInfo.totalProduct)+parseFloat(this.CartInfo.totalAdjustment)+parseFloat(this.CartInfo.totalShipping);
                            this.$set(this.CartInfo,'totalCart',totalProduct);
                            //this.CartInfo.totalProduct=totalProduct;
                            //console.log(JSON.stringify(response));
                          })
                      .catch(async response => {
                        this.formState = "error";
                        const error = await response.json();
                        this.errorMessages = [error.message];
                      });
                  } catch (error) {
                    this.formState = "error";
                    this.message = error.message;
                  }
                return {}
              },
                searchCustomer(){
                        var input= this.customer.name;
                        this.formState = "pending";
                        this.errorMessages = [];
                        try {
                                return salesService 
                                .getCustomerInfoByName(this.customer.name)
                                .then(response => {
                                        this.formState = "success";
                                        //console.log(JSON.stringify(response));
                                         this.CustomerList = response;
                                        
                                })
                                .catch(async response => {
                                        this.formState = "error";
                                        const error = await response.json();
                                        this.errorMessages = [error.message];
                                });
                        } catch (error) {
                                this.formState = "error";
                                this.message = error.message;
                        }
                        return {}
                      
                },
                showCustomer(customer){
                        this.custFirstName=customer.firstName;
                        this.custLastName=customer.lastName;
                        this.custEmail=customer.email;
                        this.custPhone=customer.phone1;
                        this.customerId=customer.userId;
                },
        },
        beforeMount() {
         this.getCartForUser();
      },
  };
 </script>
