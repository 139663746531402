<script>
import accountService from './account-service';

export default {
  name: 'LogoutForm',
  methods: {
    async logout() {
      await accountService.logout();
      window.location.assign('/');
    },
  },
};
</script>
