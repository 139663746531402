<template >
  <div
    v-show="field.$error"
    class="message">{{ message }}</div>
</template>
<script>
export default {
  name: 'ValidationMessage',
  props: {
    field: {
      type: Object,
      required: true,
    },
    errorsConditionTexts: {
      type: Array,
      required: true,
    },
    errorKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    message() {
      if (!this.field.$error) {
        this.$emit('validation-updated', this.errorKey, '');
        return '';
      }
      const error = this.errorsConditionTexts.find(errorPair =>
        errorPair.condition(this.field)
      );
      let msg = '';
      if (error) {
        msg = error.text;
      }
      this.$emit('validation-updated', this.errorKey, msg);
      return msg;
    },
  },
};
</script>
