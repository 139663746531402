var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui very padded vertical segment" }, [
    _c("div", { staticClass: "ui wide container" }, [
      _c("div", { staticClass: "ui grid" }, [
        _c("div", { staticClass: "one wide tablet two wide computer column" }),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "ui flat list" },
          _vm._l(_vm.errorMessages, function(message) {
            return _c("li", { key: message }, [_vm._v(_vm._s(message))])
          })
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "twelve wide tablet eight wide computer center aligned column"
      },
      [
        _c("h1", { staticClass: "ui large header" }, [
          _vm._v("Oeps, er is iets misgegaan")
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n          Het lijkt erop dat het ophalen van de order is mislukt.\n          "
          ),
          _c("br"),
          _vm._v(
            "\n          Klik op het logo of op de button om naar de hoofdpagina te gaan.\n          "
          ),
          _c("br"),
          _vm._v("\n          Success!\n        ")
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "ui primary button", attrs: { href: "/" } }, [
          _vm._v("naar de homepage")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }