<script>
import accountService from './account-service';
import accountEvents from './account-events';

export default {
  name: 'LoginListItem',
  data() {
    return {
      email: undefined,      
    };
  },
  computed: {
    isLoggedIn() {
      return this.email !== undefined;
    },    
  },
  mounted() {
    this.email = accountService.currentLoginId();
    
    accountEvents.$on('logged-in', email => {
      this.email = email;
    });

    accountEvents.$on('logged-out', () => {
      this.email = undefined;
    });
  },
  methods: {
    async logout() {
      await accountService.logout();
      window.location.assign('/');
    },    
  },
};
</script>
