var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui container product" }, [
    _c("div", { staticClass: "alignGrid" }, [
      _c("div", { staticClass: "ui two column grid" }, [
        _c("div", { staticClass: "column" }, [
          _c("div", { staticClass: "ui card" }, [
            _c("div", { staticClass: "image" }, [
              _c("img", { attrs: { src: _vm.productInfo.product.fullImage } })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "column" }, [
          _c("div", { staticClass: "ui card" }, [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "header productName" }, [
                _vm._v(_vm._s(_vm.productInfo.product.name))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "description" }, [
                _vm._v(_vm._s(_vm.productInfo.product.description))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ui price" }, [
                _vm._v(_vm._s(_vm.productInfo.product.listPrice))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "description" }, [
                _c("div", { staticClass: "ui quantity" }, [
                  _vm._v(" Quantity\n            ")
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.product.quantity,
                        expression: "product.quantity"
                      }
                    ],
                    staticClass: "ui dropdown",
                    attrs: { id: "quantity" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.product,
                          "quantity",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "1", selected: "" } }, [
                      _vm._v("1")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "5" } }, [_vm._v("5")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "6" } }, [_vm._v("6")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "7" } }, [_vm._v("7")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "8" } }, [_vm._v("8")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "9" } }, [_vm._v("9")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "10" } }, [_vm._v("10")])
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      _vm.addToCart(_vm.productInfo.product.catEntryId)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "ui blue submit button" }, [
                    _vm._v("Add to cart")
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }