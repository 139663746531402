var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", [
    _c("div", { staticClass: "ui form" }, [
      _c("div", { staticClass: "fields" }, [
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("input-field", {
              attrs: {
                messages: _vm.validationMessages.partNumber,
                required: true,
                validation: _vm.$v.product.partNumber,
                label: _vm.I18n("lblPartNumber"),
                placeholder: _vm.I18n("placeholderPartNumber"),
                autocomplete: "given-name",
                name: "partNumber"
              },
              model: {
                value: _vm.product.partNumber,
                callback: function($$v) {
                  _vm.$set(
                    _vm.product,
                    "partNumber",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "product.partNumber"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("input-field", {
              attrs: {
                messages: _vm.validationMessages.mfPartNumber,
                required: true,
                validation: _vm.$v.product.mfPartNumber,
                label: _vm.I18n("lblMfPartNumber"),
                placeholder: _vm.I18n("placeholderMfPartNumber"),
                autocomplete: "given-name",
                name: "mfPartNumber"
              },
              model: {
                value: _vm.product.mfPartNumber,
                callback: function($$v) {
                  _vm.$set(
                    _vm.product,
                    "mfPartNumber",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "product.mfPartNumber"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("input-field", {
              attrs: {
                messages: _vm.validationMessages.mfName,
                required: true,
                validation: _vm.$v.product.mfName,
                label: _vm.I18n("lblMfName"),
                placeholder: _vm.I18n("placeholderMfName"),
                autocomplete: "given-name",
                name: "mfName"
              },
              model: {
                value: _vm.product.mfName,
                callback: function($$v) {
                  _vm.$set(
                    _vm.product,
                    "mfName",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "product.mfName"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fields" }, [
        _c("div", { staticClass: "field" }, [
          _c("div", { staticClass: "ui checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.productDesc.published,
                  expression: "productDesc.published"
                }
              ],
              attrs: {
                id: "published",
                required: true,
                name: "published",
                type: "checkbox"
              },
              domProps: {
                checked: Array.isArray(_vm.productDesc.published)
                  ? _vm._i(_vm.productDesc.published, null) > -1
                  : _vm.productDesc.published
              },
              on: {
                change: function($event) {
                  var $$a = _vm.productDesc.published,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.productDesc,
                          "published",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.productDesc,
                          "published",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.productDesc, "published", $$c)
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", [_vm._v("Displayable")])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("div", { staticClass: "ui checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.product.markForDelete,
                  expression: "product.markForDelete"
                }
              ],
              attrs: {
                id: "markForDelete",
                required: true,
                name: "markForDelete",
                type: "checkbox"
              },
              domProps: {
                checked: Array.isArray(_vm.product.markForDelete)
                  ? _vm._i(_vm.product.markForDelete, null) > -1
                  : _vm.product.markForDelete
              },
              on: {
                change: function($event) {
                  var $$a = _vm.product.markForDelete,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.product,
                          "markForDelete",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.product,
                          "markForDelete",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.product, "markForDelete", $$c)
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", [_vm._v("For Delete")])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("Available")]),
          _vm._v(" "),
          _c("div", { staticClass: "ui radio checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.productDesc.available,
                  expression: "productDesc.available"
                }
              ],
              staticClass: "hidden",
              attrs: {
                id: "_availableY",
                name: "available",
                type: "radio",
                value: "true"
              },
              domProps: { checked: _vm._q(_vm.productDesc.available, "true") },
              on: {
                change: function($event) {
                  _vm.$set(_vm.productDesc, "available", "true")
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "_availableY" } }, [_vm._v("Yes")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ui radio checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.productDesc.available,
                  expression: "productDesc.available"
                }
              ],
              staticClass: "hidden",
              attrs: {
                id: "_availableN",
                name: "available",
                type: "radio",
                value: "false"
              },
              domProps: { checked: _vm._q(_vm.productDesc.available, "false") },
              on: {
                change: function($event) {
                  _vm.$set(_vm.productDesc, "available", "false")
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "_availableN" } }, [_vm._v("No")])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fields" }, [
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("input-field", {
              attrs: {
                messages: _vm.validationMessages.name,
                required: true,
                validation: _vm.$v.productDesc.name,
                label: _vm.I18n("lblName"),
                placeholder: _vm.I18n("placeholderProductName"),
                autocomplete: "given-name",
                name: "name"
              },
              model: {
                value: _vm.productDesc.name,
                callback: function($$v) {
                  _vm.$set(
                    _vm.productDesc,
                    "name",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "productDesc.name"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("input-field", {
              attrs: {
                messages: _vm.validationMessages.keyword,
                required: true,
                validation: _vm.$v.productDesc.keyword,
                label: _vm.I18n("lblKeyword"),
                placeholder: _vm.I18n("placeholderProductKeyword"),
                autocomplete: "given-name",
                name: "keyword"
              },
              model: {
                value: _vm.productDesc.keyword,
                callback: function($$v) {
                  _vm.$set(
                    _vm.productDesc,
                    "keyword",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "productDesc.keyword"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [_vm._v("Type")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.product.catEntType,
                  expression: "product.catEntType"
                }
              ],
              staticClass: "ui field fluid select",
              attrs: {
                id: "product.catEntType",
                required: true,
                messages: _vm.validationMessages.type,
                validation: _vm.$v.product.catEntType
              },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.product,
                    "catEntType",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c("option", { attrs: { disabled: "", value: "" } }, [
                _vm._v("Select..")
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Product" } }, [
                _vm._v("Product")
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "Package" } }, [_vm._v("Package")])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fields" }, [
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.productDesc.description,
                  expression: "productDesc.description",
                  modifiers: { trim: true }
                }
              ],
              attrs: {
                id: "productDesc.description",
                rows: "3",
                required: true,
                placeholder: _vm.I18n("placeholderProductDescription")
              },
              domProps: { value: _vm.productDesc.description },
              on: {
                blur: [
                  function($event) {
                    _vm.$v.productDesc.description.$touch()
                  },
                  function($event) {
                    _vm.$forceUpdate()
                  }
                ],
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.productDesc,
                      "description",
                      $event.target.value.trim()
                    )
                  },
                  function($event) {
                    _vm.$v.productDesc.description.$reset()
                  }
                ]
              }
            }),
            _vm._v(" "),
            _c("validation-message", {
              attrs: {
                field: _vm.$v.productDesc.description,
                "errors-condition-texts": [
                  {
                    condition: function(f) {
                      return !f.required
                    },
                    text: _vm.I18n("lblProductDescription")
                  }
                ],
                "error-key": "productDescription"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fields" }, [
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("input-field", {
              attrs: {
                type: "number",
                min: "0",
                max: "9999",
                messages: _vm.validationMessages.quantity,
                required: true,
                validation: _vm.$v.stock.quantity,
                label: _vm.I18n("lblQuantity"),
                placeholder: _vm.I18n("placeholderProductQuantity"),
                autocomplete: "given-name",
                name: "quantity"
              },
              model: {
                value: _vm.stock.quantity,
                callback: function($$v) {
                  _vm.$set(_vm.stock, "quantity", _vm._n($$v))
                },
                expression: "stock.quantity"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("input-field", {
              attrs: {
                type: "number",
                min: "0",
                max: "99999",
                messages: _vm.validationMessages.listPrice,
                required: true,
                validation: _vm.$v.product.listPrice,
                label: _vm.I18n("lblListPrice"),
                placeholder: _vm.I18n("placeholderProductListPrice"),
                autocomplete: "given-name",
                name: "listPrice"
              },
              model: {
                value: _vm.product.listPrice,
                callback: function($$v) {
                  _vm.$set(_vm.product, "listPrice", _vm._n($$v))
                },
                expression: "product.listPrice"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fields" }, [
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("input-field", {
              attrs: {
                messages: _vm.validationMessages.thumbnail,
                required: false,
                validation: _vm.$v.productDesc.thumbnail,
                label: _vm.I18n("lblThumbnail"),
                placeholder: _vm.I18n("placeholderProductThumbnail"),
                autocomplete: "given-name",
                name: "thumbnail"
              },
              model: {
                value: _vm.productDesc.thumbnail,
                callback: function($$v) {
                  _vm.$set(
                    _vm.productDesc,
                    "thumbnail",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "productDesc.thumbnail"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("input-field", {
              attrs: {
                messages: _vm.validationMessages.fullImage,
                required: false,
                validation: _vm.$v.productDesc.fullImage,
                label: _vm.I18n("lblFullImage"),
                placeholder: _vm.I18n("placeholderProductFullImage"),
                autocomplete: "given-name",
                name: "fullImage"
              },
              model: {
                value: _vm.productDesc.fullImage,
                callback: function($$v) {
                  _vm.$set(
                    _vm.productDesc,
                    "fullImage",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "productDesc.fullImage"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "ui primary button",
          class: { "loading disabled": _vm.formState === "pending" },
          attrs: {
            id: "add",
            disabled: !_vm.isFormValid || _vm.formState === "pending",
            type: "submit"
          },
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.addOrUpdateProduct()
            }
          }
        },
        [_vm._v(_vm._s(_vm.button.text))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }