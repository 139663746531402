<template>
  <i
    :class="{'red close': field.$dirty && field.$invalid, 'green check': field.$dirty && !field.$invalid}"
    class="ui icon"/>
</template>

<script>
export default {
  name: 'ValidationIcon',
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
};
</script>
