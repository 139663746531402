import Vue from 'vue';
import langSubString from '../common/multi-lang-service';
//import getBrandInfo from '../common/brand-service';

function doRequest(method, ...params) {
  return Vue.http[method](...params).then(res => {
    if (res.body !== '') {
      return res.json();
    }

    return {};
  });
}

const ApiService = {
  account: {
    login(logonId, password) {
      return doRequest('post', `${langSubString()}/api/users/login`, {
        logonId,
        password,
      });
    },    
    logout() {
      return doRequest('post', `${langSubString()}/api/users/logout`);
    },
    registerUser(contact) {
      return doRequest('post', `${langSubString()}/api/users/register`, contact);
    },
    sendEmail(firstName,email,subject,comments) {
      return doRequest('post', `${langSubString()}/api/users/send-mail`, {
        firstName, 
        email, 
        subject, 
        comments,
      });      
    },    
  },
  catalog: {
    addProduct(product) {
      return doRequest('post', `${langSubString()}/api/catalog/product/add`, product);
    },
    updateProduct(product) {
      return doRequest('put', `${langSubString()}/api/catalog/product/update`, product);
    },
    getProductList(){
      return doRequest('get', `${langSubString()}/api/catalog/product-list/byStore`);
    },
    getProductByPartNumber(partNumber){
      return doRequest('get', `${langSubString()}/api/catalog/product/${partNumber}`);
    },
  },
  shipmode: {
    addShipmode(shipmode) {
      return doRequest('post', `${langSubString()}/api/shipmodes/add`, shipmode);
    },
    
    updateShipmode(shipmode) {
      return doRequest('put', `${langSubString()}/api/shipmodes/update`, shipmode);
    },
    
    getShipmodes(){
      return doRequest('get', `${langSubString()}/api/shipmodes/all`);
    },

    getShipmodeByName(shipmodeName){
      return doRequest('get', `${langSubString()}/api/shipmodes/get/byMode/${shipmodeName}`);
    },
  },
  cart:{
    addItemToCart(orderItem) {
      return doRequest('post', `${langSubString()}/api/cart/item/add`, orderItem);
    },
    getCurrentCart(){
      return doRequest('get', `${langSubString()}/api/cart/self`);
    },

    /*
    updateItemInCart(orderItem) {
      return doRequest('put', `${langSubString()}/api/cart/item/update`, orderItem);
    },
    updateCart(order){
      return doRequest('put', `${langSubString()}/api/cart/update`,order);
    },
   
    */
  },
  sales:{
    saveCustomer(contact){
      return doRequest('post', `${langSubString()}/api/sales/customer/save`, contact);
    },
    getCustomers(){
      return doRequest('get', `${langSubString()}/api/sales/customers/all`);
    },
    getCustomerInfo(contactId){
      return doRequest('get', `${langSubString()}/api/sales/customer/contact/byId/${contactId}`);
    },
    getCustomerInfoByName(name){
      return doRequest('get', `${langSubString()}/api/sales/customer/search/byName/${name}`);
    },
    
  },
};

export default ApiService;
